import React from 'react';
import Popup from 'reactjs-popup';
import CustomButton from '../custom-button/custom-button.component';
import {
   AcknowledgeActionContainer,
   CustomButtonContainer,
} from './acknowledge-action.styles';

const AcknowledgeAction = ({
   isPopUpOpen,
   setIsPopUpOpen,
   yesFunction,
   noFunction,
}) => {
   return (
      isPopUpOpen && (
         <Popup modal open={isPopUpOpen} onClose={() => setIsPopUpOpen(false)}>
            <AcknowledgeActionContainer>
               <div>مطمئنید؟</div>
               <CustomButtonContainer>
                  <CustomButton
                     isGreen
                     onClick={() => {
                        setIsPopUpOpen(false);
                        !!yesFunction && yesFunction();
                     }}
                  >
                     بله
                  </CustomButton>
                  <CustomButton
                     isRed
                     onClick={() => {
                        setIsPopUpOpen(false);
                        !!noFunction && noFunction();
                     }}
                  >
                     خیر
                  </CustomButton>
               </CustomButtonContainer>
            </AcknowledgeActionContainer>
         </Popup>
      )
   );
};

export default AcknowledgeAction;
