import styled from 'styled-components';

export const GroupContainer = styled.div`
   width: ${(props) => (props.width ? props.width : 'fit-content')};
   display: flex;
   flex-direction: row;
   margin: 5px 0;
   cursor: pointer;
   align-items: center;
   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      width: fit-content;
   }
`;

export const RadioboxInputLabel = styled.label`
   display: block;
   color: ${(props) => props.theme.colors.dark};
   font-size: 1.2rem;
   font-weight: normal;
   pointer-events: none;
   right: 25px;
`;

export const HiddenRadiobox = styled.input.attrs({ type: 'radio' })`
   // Hide radiobox visually but remain accessible to screen readers.
   // Source: https://polished.js.org/docs/#hidevisually
   border: 0;
   clip: rect(0 0 0 0);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   white-space: nowrap;
   width: 1px;
`;

export const Label = styled.label`
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   background: ${(props) =>
      props.checked ? props.theme.colors.secondary : 'none'};
   border: ${(props) =>
      props.checked
         ? '2px solid ' + props.theme.colors.secondary
         : '2px solid #e6e6e6'};

   ${HiddenRadiobox}:focus + & {
      box-shadow: 0 0 0 3px MistyRose;
   }

   padding: 10px 15px 10px 15px;
   transition: all 150ms;
`;

export const RadioboxContainer = styled.div`
   display: inline-block;
   vertical-align: middle;
   display: none;
`;

export const DeleteButton = styled.div`
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   cursor: pointer;
   font-size: ${(props) => props.theme.fontSizes.large};
   margin-right: 5px;
   color: #333333;
   font-weight: normal;
   border-radius: 100%;

   :hover {
      background-color: #ffbdad;
      color: #de350b;
      fill: #de350b;
      stroke: #de350b;
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      color: #de350b;
      fill: #de350b;
      stroke: #de350b;
   }
`;

export const SVGIcon = styled.svg`
   display: block;
   margin: auto;
   line-height: 1;
`;
