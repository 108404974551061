import React from 'react';
import {
   ProductQuantityCompactContainer,
   Detail,
   ImageContainer,
   RemoveButtonContainer,
} from './product-quantity-compact.styles';
import AcknowledgeAction from '../acknowledge-action/acknowledge-action.component';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
   getPersianPositiveNumbers,
   persianNumberToEnglish,
} from '../../utils/font.utils';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import {
   changeProductQuantityOfVariantStart,
   removeProductQuantityFromVariantStart,
} from '../../redux/inventory/inventory.actions';

const ProductQuantityCompact = ({ shopId, variantId, productQuantity }) => {
   const {
      product: { name, imageLink },
      quantity,
   } = productQuantity;
   const [isDelPopupOpen, setIsDelPopupOpen] = useState(false);
   const dispatch = useDispatch();
   const [anyChange, setAnyChange] = useState(false);
   const [q, setQ] = useState(quantity);

   console.log(shopId, variantId, productQuantity.id);
   return (
      <ProductQuantityCompactContainer>
         <ImageContainer>
            <img src={imageLink} alt={name} />
         </ImageContainer>
         <Detail>{name}</Detail>
         <FormInput
            type='text'
            handleChange={(e) => {
               setAnyChange(true);
               setQ(getPersianPositiveNumbers(e.target.value));
            }}
            value={q}
            label='تعداد'
            required
         />
         <CustomButton
            onClick={() =>
               dispatch(
                  changeProductQuantityOfVariantStart(
                     shopId,
                     variantId,
                     productQuantity.id,
                     persianNumberToEnglish(q)
                  )
               )
            }
            isSmall
            disabled={!anyChange}
         >
            ثبت
         </CustomButton>

         <RemoveButtonContainer onClick={() => setIsDelPopupOpen(true)}>
            &#10005;
         </RemoveButtonContainer>

         <AcknowledgeAction
            isPopUpOpen={isDelPopupOpen}
            setIsPopUpOpen={setIsDelPopupOpen}
            yesFunction={() =>
               dispatch(
                  removeProductQuantityFromVariantStart(
                     shopId,
                     variantId,
                     productQuantity.id
                  )
               )
            }
         />
      </ProductQuantityCompactContainer>
   );
};

export default ProductQuantityCompact;
