import { createSelector } from 'reselect';

const selectInventory = (state) => state.inventory;

export const selectProducts = createSelector(
   [selectInventory],
   (inventory) => inventory.products
);

export const selectIsProductsLoaded = createSelector(
   [selectInventory],
   (inventory) => !!inventory.products
);
