import styled from 'styled-components';

export const SortContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   flex-wrap: wrap;
   width: 100%;
   direction: rtl;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      justify-content: space-evenly;
   }
`;
