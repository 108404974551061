import styled from 'styled-components';

export const GroupContainer = styled.div`
   font-size: ${(props) => props.theme.fontSizes.medium};
   cursor: pointer;
   margin: 5px;
`;

export const Label = styled.label`
   display: flex;
   flex-direction: row;
   align-items: center;
`;

export const CheckboxInputLabel = styled.span`
   position: relative;
   left: -10px;
   top: -3px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
   // Hide checkbox visually but remain accessible to screen readers.
   // Source: https://polished.js.org/docs/#hidevisually
   border: 0;
   clip: rect(0 0 0 0);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   white-space: nowrap;
   width: 1px;
`;

export const StyledCheckbox = styled.div`
   display: inline-block;
   width: 16px;
   height: 16px;
   background: ${(props) => (props.checked ? 'SteelBlue' : 'LightCyan')};
   ${HiddenCheckbox}:focus + & {
      box-shadow: 0 0 0 3px MistyRose;
   }
   border-radius: 3px;
   transition: all 150ms;
`;

export const CheckboxContainer = styled.div`
   display: inline-block;
   vertical-align: middle;
`;

export const Icon = styled.svg`
   fill: none;
   stroke: LightCyan;
   stroke-width: 2px;
`;
