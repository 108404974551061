import React, { useState } from 'react';

//Router Hooks
import { useNavigate, useLocation } from 'react-router-dom';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Redux Selectors
import { selectCurrentUser } from '../../redux/user/user.selectors';

//Components
import SignInAndSignUpPage from '../../pages/sign-in-and-sign-up/sign-in-and-sign-up.component';

//Libraries
import Popup from 'reactjs-popup';

//Assetes
// import { ReactComponent as Logo } from '../../assets/DareaghabLogo.png';
import Logo from '../../assets/botsigi.png';

//Styles
import 'reactjs-popup/dist/index.css';
import {
   HeaderContainer,
   LogoContainer,
   OptionsContainer,
   BaseSearchContainer,
   AddedSearchContainer,
   LoginLink,
   SearchAndMenuContainer,
   MenuContainer,
   BaseNavbarContainer,
   ExtendedMenuContainer,
   OptionLink,
   ExtendedMenuOptionLink,
} from './header.styles';
import SearchBar from '../search-bar/search-bar.components';

import BurgerIcon from '../burger-icon/burger-icon.component';
import { signOut } from '../../redux/user/user.actions';
import CustomButton from '../custom-button/custom-button.component';
import DigiCalculator from '../digi-calculator/digi-calculator.component';
import { selectUserSellers } from '../../redux/user-seller/user-seller.selectors';
import AddChangeProduct from '../add-change-product/add-change-product.component';

export const Header = () => {
   const dispatch = useDispatch();
   const location = useLocation();

   const sellers = useSelector(selectUserSellers);

   const [isMenuOpen, setIsMenuOpen] = useState(false);

   const [isCalculatorPopupOpen, setIsCalculatorPopupOpen] = useState(false);

   const currentUser = useSelector(selectCurrentUser);

   //   const handleSearchChange = (event) => {
   //       const { value } = event.target;
   //       if (value.length > 1 && location.pathname !== '/shop/search') {
   //          history.push('/shop/search');
   //       }
   //       dispatch(setSearchQuery(value));
   //    };

   return (
      <HeaderContainer>
         <BaseNavbarContainer>
            <OptionsContainer>
               <CustomButton
                  isSmall
                  inverted
                  onClick={() => {
                     // console.log('click');
                     setIsCalculatorPopupOpen(true);
                  }}
               >
                  محاسبه سریع
               </CustomButton>
               {currentUser ? (
                  <LoginLink onClick={() => dispatch(signOut())}>
                     خروج
                  </LoginLink>
               ) : (
                  location.pathname !== '/signin' && (
                     <Popup trigger={<LoginLink>ورود</LoginLink>} modal>
                        <SignInAndSignUpPage />
                     </Popup>
                  )
               )}

               {currentUser && (
                  <OptionLink to='/all-sellers/'>تنظیمات سلرها</OptionLink>
               )}
               {sellers &&
                  sellers
                     .sort((seller1, seller2) => seller2.id - seller1.id)
                     .map((seller, idx) => (
                        <OptionLink
                           thisPage={location.pathname.endsWith(
                              '/all-sellers/' + seller.id
                           )}
                           key={idx}
                           to={'/all-sellers/' + seller.id}
                        >
                           {seller.name}
                        </OptionLink>
                     ))}
               {sellers &&
                  sellers
                     .sort((seller1, seller2) => seller2.id - seller1.id)
                     .map((seller, idx) => (
                        <OptionLink
                           thisPage={location.pathname.endsWith(
                              '/all-sellers/' + seller.id + '/orders/'
                           )}
                           key={idx}
                           to={'/all-sellers/' + seller.id + '/orders/'}
                        >
                           سفارش {seller.name}
                        </OptionLink>
                     ))}
               <AddChangeProduct />
            </OptionsContainer>

            <LogoContainer to='/'>
               <img
                  src={Logo}
                  alt='digikala Link'
                  aria-label='Maleto'
                  className='logo'
                  onClick={() => {
                     setIsMenuOpen(false);
                     // dispatch(setSearchQuery(''));
                  }}
               />
            </LogoContainer>
         </BaseNavbarContainer>
         <SearchAndMenuContainer>
            <AddedSearchContainer>
               {
                  // <SearchBar
                  //    placeHolder='جستجو'
                  //    handleChange={handleSearchChange}
                  // ></SearchBar>
               }
            </AddedSearchContainer>
            <MenuContainer
               onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
               }}
            >
               <BurgerIcon isMenuOpen={isMenuOpen} />
            </MenuContainer>
         </SearchAndMenuContainer>
         {isMenuOpen && (
            <ExtendedMenuContainer
               onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
               }}
            >
               {currentUser && (
                  <ExtendedMenuOptionLink to='/all-sellers/'>
                     تنظیمات سلرها
                  </ExtendedMenuOptionLink>
               )}
               {sellers &&
                  sellers.map((seller, idx) => (
                     <ExtendedMenuOptionLink
                        key={idx}
                        to={'/all-sellers/' + seller.id}
                     >
                        {seller.name}
                     </ExtendedMenuOptionLink>
                  ))}
            </ExtendedMenuContainer>
         )}
         {isCalculatorPopupOpen && (
            <Popup
               modal
               open={isCalculatorPopupOpen}
               onClose={() => setIsCalculatorPopupOpen(false)}
            >
               <DigiCalculator />
            </Popup>
         )}
      </HeaderContainer>
   );
};

export default Header;
