import { all, call } from 'redux-saga/effects';

//Sagas
import { userSagas } from './user/user.sagas';
import { sellerSagas } from './seller/seller.sagas';
import { userSellerSagas } from './user-seller/user-seller.sagas';
import { inventorySagas } from './inventory/inventory.sagas';
import { shopSagas } from './shop/shop.sagas';

export default function* rootSaga() {
   yield all([
      call(userSagas),
      call(sellerSagas),
      call(userSellerSagas),
      call(inventorySagas),
      call(shopSagas),
   ]);
}
