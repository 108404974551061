import InventoryActionTypes from '../inventory/inventory.types';
import SellerActionTypes from './seller.types';
import { replceNewDKPC } from './seller.utils';

const INITIAL_STATE = {
   dkpcs: [],
   orders: undefined,
   error: undefined,
};

const sellerReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case SellerActionTypes.GET_SELLER_DKPCS_START:
         return {
            ...state,
            dkpcs: [],
         };
      case SellerActionTypes.GET_SELLER_DKPCS_SUCCESS:
         return {
            ...state,
            dkpcs: action.payload,
         };
      case SellerActionTypes.GET_SELLER_ORDERS_SUCCESS:
         return {
            ...state,
            orders: action.payload,
         };
      case SellerActionTypes.ADD_SELLER_DKPC_SUCCESS:
         return {
            ...state,
            dkpcs: [...state.dkpcs, action.payload],
         };
      case SellerActionTypes.SET_SELLER_DKPC_SUCCESS:
         return {
            ...state,
            dkpcs: replceNewDKPC(state.dkpcs, action.payload),
         };
      case SellerActionTypes.DEL_SELLER_DKPC:
         return {
            ...state,
            dkpcs: state.dkpcs.filter(
               (dkpc) => dkpc.id !== action.payload.dkpcId
            ),
         };
      case SellerActionTypes.TOGGLE_SELLER_DKPC_ISKKON_SUCCESS:
         return {
            ...state,
            dkpcs: replceNewDKPC(state.dkpcs, action.payload),
         };
      case SellerActionTypes.TOGGLE_SELLER_DKPC_ISACTIVEBOT_SUCCESS:
         return {
            ...state,
            dkpcs: replceNewDKPC(state.dkpcs, action.payload),
         };
      case SellerActionTypes.REMOVE_SELLER_DKPC_ORDER:
         return {
            ...state,
            orders: state.orders.filter((dkpc) => {
               return dkpc.dkpcNum !== action.payload;
            }),
         };
      case InventoryActionTypes.ADD_CHANGE_PRODUCT_QUANTITY_TO_DKPC_SUCCESS:
      case InventoryActionTypes.REMOVE_PRODUCT_QUANTITY_FROM_DKPC_SUCCESS:
      case InventoryActionTypes.CHANGE_PRODUCT_QUANTITY_OF_DKPC_SUCCESS:
         return {
            ...state,
            dkpcs: replceNewDKPC(state.dkpcs, action.payload),
         };

      case SellerActionTypes.GET_SELLER_DKPCS_FAILURE:
      case SellerActionTypes.ADD_SELLER_DKPC_FAILURE:
         return {
            ...state,
            error: action.payload,
         };

      default:
         return state;
   }
};

export default sellerReducer;
