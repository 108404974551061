import { takeLatest, put, all, call, select } from 'redux-saga/effects';

//Libraries
import axios from 'axios';

import InventoryActionTypes from './inventory.types';

//Utils
import { selectUserToken } from '../user/user.selectors';
import {
   addChangeProductFailure,
   addChangeProductQuantityToDKPCSuccess,
   addChangeProductQuantityToVariantFailure,
   addChangeProductQuantityToVariantSuccess,
   addChangeProductSuccess,
   changeProductQuantityOfDKPCSuccess,
   changeProductQuantityOfVariantFailure,
   changeProductQuantityOfVariantSuccess,
   getProductsFailure,
   getProductsSuccess,
   removeProductQuantityFromDKPCSuccess,
   removeProductQuantityFromVariantFailure,
   removeProductQuantityFromVariantSuccess,
} from './inventory.actions';
import { toastError, toastSuccess } from '../../utils/toast-redux.utils';
import { downloadFile } from '../../utils/download.utils';

export function* getProductsSaga() {
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.get('/inventory/', config);
      // console.log('inventory', response);
      yield put(getProductsSuccess(response.data));
   } catch (error) {
      yield put(getProductsFailure(error.response.data.message));
   }
}

export function* addChangeProductQuantityToVariantSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post(
         '/inventory/variant-pq/',
         payload,
         config
      );
      if (payload.shopId === 0)
         yield put(addChangeProductQuantityToDKPCSuccess(response.data));
      else yield put(addChangeProductQuantityToVariantSuccess(response.data));
   } catch (error) {
      yield put(
         addChangeProductQuantityToVariantFailure(error.response.data.message)
      );
   }
}

export function* removeProductQuantityFromVariantSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      // console.log('dkpc-pq1', payload);
      const response = yield axios.delete('/inventory/variant-pq/', {
         data: payload,
         ...config,
      });
      // console.log('dkpc-pq2', response);

      if (payload.shopId === 0)
         yield put(removeProductQuantityFromDKPCSuccess(response.data));
      else yield put(removeProductQuantityFromVariantSuccess(response.data));
   } catch (error) {
      yield put(
         removeProductQuantityFromVariantFailure(error.response.data.message)
      );
   }
}
export function* changeProductQuantityOfVariantSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      // console.log('dkpc-pq1', payload);
      const response = yield axios.put(
         '/inventory/variant-pq/',
         payload,
         config
      );
      // console.log('dkpc-pq2', response);

      if (payload.shopId === 0)
         yield put(changeProductQuantityOfDKPCSuccess(response.data));
      else yield put(changeProductQuantityOfVariantSuccess(response.data));
   } catch (error) {
      yield put(
         changeProductQuantityOfVariantFailure(error.response.data.message)
      );
   }
}

export function* addChangeProductSaga({ payload: { p, callAfter } }) {
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post('/inventory/', p, config);
      // console.log(response.data);
      yield put(addChangeProductSuccess(response.data.data));
      yield toastSuccess(response.data.message);
      if (callAfter) yield callAfter();
   } catch (error) {
      // console.log('errot', error);
      yield put(addChangeProductFailure(error.response.data.message));
   }
}
export function* transformExcelSaga({ payload }) {
   console.log('payloadTE', payload);
   try {
      const jwt_token = yield select(selectUserToken);
      const config = {
         headers: {
            Content_type: 'multipart/form-data',
            Authorization: 'Bearer ' + jwt_token,
         },
         responseType: 'blob',
      };
      var fd = new FormData();
      fd.append('fileToBeTransformed', payload);
      const response = yield axios.post('/inventory/transform/', fd, config);
      console.log(
         response,
         response.headers,
         response.headers['content-disposition']
      );
      downloadFile(
         response,
         response.headers['content-disposition'].split('"')[1]
      );
      // const url = window.URL.createObjectURL(new Blob([response.data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'خروج از انبار.xlsx');
      // document.body.appendChild(link);
      // link.click();
   } catch (error) {
      console.log('error', error);
   }
}

export function* onGetProductsStart() {
   yield takeLatest(InventoryActionTypes.GET_PRODUCTS_START, getProductsSaga);
}

export function* onAddChangeProductQuantityToVariantStart() {
   yield takeLatest(
      InventoryActionTypes.ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_START,
      addChangeProductQuantityToVariantSaga
   );
}
export function* onRemoveProductQuantityFromVariantStart() {
   yield takeLatest(
      InventoryActionTypes.REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_START,
      removeProductQuantityFromVariantSaga
   );
}

export function* onChangeProductQuantityOofVariantStart() {
   yield takeLatest(
      InventoryActionTypes.CHANGE_PRODUCT_QUANTITY_OF_VARIANT_START,
      changeProductQuantityOfVariantSaga
   );
}

export function* onAddChangeProductStart() {
   yield takeLatest(
      InventoryActionTypes.ADD_CHANGE_PRODUCT_START,
      addChangeProductSaga
   );
}

export function* onTransformExcel() {
   yield takeLatest(InventoryActionTypes.TRANSFORM_EXCEL, transformExcelSaga);
}

export function* inventorySagas() {
   yield all([
      call(onGetProductsStart),
      call(onAddChangeProductQuantityToVariantStart),
      call(onRemoveProductQuantityFromVariantStart),
      call(onChangeProductQuantityOofVariantStart),
      call(onAddChangeProductStart),
      call(onTransformExcel),
   ]);
}
