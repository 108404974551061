import styled, { css } from 'styled-components';
import theme from '../../utils/theme.utils';
const subColor = theme.colors.darkGrey;
const mainColor = theme.colors.dark;

const shrinkLabelStyles = css`
   top: -14px;
   font-size: ${(props) => props.theme.fontSizes.small};
   color: ${subColor};

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      font-size: ${(props) => props.theme.fontSizes.smaller};
   }
`;

export const GroupContainer = styled.div`
   position: relative;
   margin: 20px 0;

   input[type='password'] {
      letter-spacing: 0.3em;
   }
`;

export const FormInputContainer = styled.input`
   background: none;
   background-color: ${theme.colors.primary};
   color: ${(props) => (props.disabled ? subColor : mainColor)};
   font-size: ${(props) => props.theme.fontSizes.large};
   padding: 10px 10px 10px 5px;
   display: block;
   width: 100%;
   border: none;
   border-radius: 0;
   border-bottom: 1px solid ${subColor};

   :-webkit-autofill,
   :-webkit-autofill:hover,
   :-webkit-autofill:focus,
   :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.tertiary} inset !important;
      box-shadow: 0 0 0 30px ${theme.colors.tertiary} inset !important;
   }

   &:focus {
      outline: none;
   }

   &:focus ~ label {
      ${shrinkLabelStyles}
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      font-size: ${(props) => props.theme.fontSizes.medium};
   }
`;

export const FormInputLabel = styled.label`
   color: ${mainColor};
   font-size: ${(props) => props.theme.fontSizes.large};
   font-weight: normal;
   position: absolute;
   pointer-events: none;
   right: 10px;
   top: 10px;
   transition: 300ms ease all;

   &.shrink {
      ${shrinkLabelStyles}
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      font-size: ${(props) => props.theme.fontSizes.small};
   }
`;

export const RequiredStar = styled.span`
   color: ${(props) => props.theme.colors.error};
`;

export const ValidateTik = styled.span`
   color: ${(props) => props.theme.colors.success};
   margin: 0px 5px;
`;

export const ValidateCross = styled.span`
   color: ${(props) => props.theme.colors.error};
   margin: 0px 5px;
`;

export const SubLabel = styled.span`
   position: absolute;
   bottom: -25px;
   margin: 0px;
`;
