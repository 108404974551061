import UserActionTypes from '../user/user.types';
import UserSellerActionTypes from './user-seller.types';
import { replaceNewBrnadSeller } from './user-seller.utils';

const INITIAL_STATE = {
   sellers: null,
   error: null,
};

const userSellerReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case UserSellerActionTypes.GET_USER_SELLERS_SUCCESS:
         return {
            ...state,
            sellers: action.payload,
            error: null,
         };
      case UserSellerActionTypes.GET_USER_SELLERS_FAILURE:
         return {
            ...state,
            error: action.payload,
         };
      case UserSellerActionTypes.ADD_USER_SELLERS_SUCCESS:
         return {
            ...state,
            sellers: [...state.sellers, action.payload],
         };

      case UserSellerActionTypes.DEL_USER_SELLER:
         return {
            ...state,
            sellers: state.sellers.filter(
               (seller) => seller.id !== action.payload
            ),
         };
      case UserSellerActionTypes.TOGGLE_SELLER_IS_KK_SUCCESS:
         return {
            ...state,
            sellers: [
               ...state.sellers.filter(
                  (seller) => seller.id !== action.payload.id
               ),
               action.payload,
            ],
         };
      case UserSellerActionTypes.TOGGLE_BRAND_SELLER_IS_KK_SUCCESS:
         return {
            ...state,
            sellers: replaceNewBrnadSeller(state.sellers, action.payload),
         };
      case UserSellerActionTypes.SET_BRAND_SELLER_OFFLIMIT_SUCCESS:
         return {
            ...state,
            sellers: replaceNewBrnadSeller(state.sellers, action.payload),
         };
      case UserSellerActionTypes.TOGGLE_SELLER_RANDOM_KK_SUCCESS:
         return {
            ...state,
            sellers: [
               ...state.sellers.filter(
                  (seller) => seller.id !== action.payload.id
               ),
               action.payload,
            ],
         };
      case UserActionTypes.SIGN_OUT:
         return {
            ...state,
            sellers: null,
         };
      default:
         return state;
   }
};

export default userSellerReducer;
