import styled from 'styled-components';
import { persianNumberToEnglish } from '../../utils/font.utils';

export const ProductContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   direction: rtl;

   border-bottom: 1px solid ${(props) => props.theme.colors.black};

   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      flex-wrap: wrap;
   }
`;

export const ImageContainer = styled.a`
   height: 150px;
   min-width: 80px;

   img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: ${(props) =>
         props.estedadAnbaresh
            ? `2px solid ${props.theme.colors.fortiary}`
            : 'none'};
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      height: 100px;
      min-width: 50px;
      width: 30%;
   }
`;

export const NameContainer = styled.a`
   font-size: ${(props) => props.theme.fontSizes.small};
   text-decoration: none;
   color: ${(props) =>
      props.isBuyBox ? props.theme.colors.fortiary : props.theme.colors.dark};
   font-weight: ${(props) => (props.isBuyBox ? 'bold' : 'unset')};
`;

export const DigiLink = styled.a`
   width: 20%;
   margin: 5px;
   min-width: 70px;
   text-decoration: none;
   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      width: 60%;
   }
`;

export const ProductFormContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      flex-wrap: wrap;
   }
`;

export const PriceContainer = styled.div`
   width: 20%;
   margin: 5px;
   min-width: 80px;
`;

export const PriceContainerWide = styled.div`
   width: 20%;
   margin: 5px;
   min-width: 150px;
`;
export const PriceContainerNarrow = styled.div`
   width: 10%;
   margin: 5px;
   min-width: 40px;
`;

export const RemoveButtonContainer = styled.div`
   padding: 10px 20px;
   color: ${(props) => props.theme.colors.error};
   cursor: pointer;
`;

export const IsKKContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 80px;
`;

export const ActiveAndRemoveButtons = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100px;
`;

export const StockContainer = styled.div`
   margin: 0px;
`;

export const AvgMonthSelling = styled.span`
   display: flex;
   flex-direction: column;
   cursor: pointer;
`;

export const EstimatedSellingTime = styled.span`
   color: ${(props) =>
      Number(
         persianNumberToEnglish(
            props.children.slice(0, props.children.length - 1)
         )
      ) > 2
         ? props.theme.colors.error
         : props.theme.colors.dark};
`;

export const ListContainer = styled.div`
   direction: rtl;
   padding: 20px;
   max-height: 95vh;
   overflow: auto;

   .title {
      margin: 10px;
      padding: 10px;
      font-weight: bolder;
      font-size: medium;
   }
   .second {
      border-top: 2px solid ${(props) => props.theme.colors.fortiary};
   }
`;
