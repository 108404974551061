import React from 'react';
import {
   BurgerIconContainer,
   BurgerIconLine1,
   BurgerIconLine2,
   BurgerIconLine3,
} from './burger-icon.styles';

const BurgerIcon = ({ isMenuOpen }) => {
   const scale = 0.6;
   return (
      <BurgerIconContainer>
         <BurgerIconLine1 scale={scale} isMenuOpen={isMenuOpen} />
         <BurgerIconLine2 scale={scale} isMenuOpen={isMenuOpen} />
         <BurgerIconLine3 scale={scale} isMenuOpen={isMenuOpen} />
      </BurgerIconContainer>
   );
};

export default BurgerIcon;
