import SellerActionTypes from './seller.types';

export const getSellerDKPCsStart = (sellerId) => ({
   type: SellerActionTypes.GET_SELLER_DKPCS_START,
   payload: sellerId,
});

export const getSellerDKPCsSuccess = (dkpcs) => ({
   type: SellerActionTypes.GET_SELLER_DKPCS_SUCCESS,
   payload: dkpcs,
});

export const getSellerDKPCsFailure = (errorMessage) => ({
   type: SellerActionTypes.GET_SELLER_DKPCS_FAILURE,
   payload: errorMessage,
});

export const addSellerDKPCStart = (sellerId, dkpc, closePopup) => ({
   type: SellerActionTypes.ADD_SELLER_DKPC_START,
   payload: { sellerId, dkpc, closePopup },
});

export const addSellerDKPCSuccess = (dkpc) => ({
   type: SellerActionTypes.ADD_SELLER_DKPC_SUCCESS,
   payload: dkpc,
});

export const addSellerDKPCFailure = (errorMessage) => ({
   type: SellerActionTypes.ADD_SELLER_DKPC_FAILURE,
   payload: errorMessage,
});

export const setSellerDKPCStart = (dkpc, sellerId) => ({
   type: SellerActionTypes.SET_SELLER_DKPC_START,
   payload: { sellerId, dkpc },
});

export const setSellerDKPCSuccess = (dkpc) => ({
   type: SellerActionTypes.SET_SELLER_DKPC_SUCCESS,
   payload: dkpc,
});

export const setSellerDKPCFailure = (errorMessage) => ({
   type: SellerActionTypes.SET_SELLER_DKPC_FAILURE,
   payload: errorMessage,
});

export const delSellerDKPC = (dkpcId, sellerId) => ({
   type: SellerActionTypes.DEL_SELLER_DKPC,
   payload: { sellerId, dkpcId },
});

export const checkSellerDKPC = (dkpcId, sellerId) => ({
   type: SellerActionTypes.CHECK_SELLER_DKPC,
   payload: { sellerId, dkpcId },
});

export const toggleSellerDKPCIsKKOnStart = (dkpcId, sellerId) => ({
   type: SellerActionTypes.TOGGLE_SELLER_DKPC_ISKKON_START,
   payload: { sellerId, dkpcId },
});

export const toggleSellerDKPCIsKKOnSuccess = (dkpc) => ({
   type: SellerActionTypes.TOGGLE_SELLER_DKPC_ISKKON_SUCCESS,
   payload: dkpc,
});

export const toggleSellerDKPCIsActiveBotStart = (dkpcId, sellerId) => ({
   type: SellerActionTypes.TOGGLE_SELLER_DKPC_ISACTIVEBOT_START,
   payload: { sellerId, dkpcId },
});

export const toggleSellerDKPCIsActiveBotSuccess = (dkpc) => ({
   type: SellerActionTypes.TOGGLE_SELLER_DKPC_ISACTIVEBOT_SUCCESS,
   payload: dkpc,
});

export const getExcelStart = (sellerId) => ({
   type: SellerActionTypes.GET_SELLER_EXCEL_START,
   payload: sellerId,
});

export const getSellerOrdersStart = (sellerId) => ({
   type: SellerActionTypes.GET_SELLER_ORDERS_START,
   payload: sellerId,
});

export const getSellerOrdersSuccess = (orders) => ({
   type: SellerActionTypes.GET_SELLER_ORDERS_SUCCESS,
   payload: orders,
});

export const getSellerOrdersFailure = (errorMessage) => ({
   type: SellerActionTypes.GET_SELLER_ORDERS_FAILURE,
   payload: errorMessage,
});

export const checkSellerDKPCOrder = (dkpcId, sellerId) => ({
   type: SellerActionTypes.CHECK_SELLER_DKPC_ORDER,
   payload: { sellerId, dkpcId },
});

export const removeSellerDKPCOrder = (dkpcId) => ({
   type: SellerActionTypes.REMOVE_SELLER_DKPC_ORDER,
   payload: dkpcId,
});
