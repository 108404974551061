const ShopActionTypes = {
   GET_SHOP_START: 'GET_SHOP_START',
   GET_SHOP_SUCCESS: 'GET_SHOP_SUCCESS',
   GET_SHOP_FAILURE: 'GET_SHOP_FAILURE',

   ADD_CHANGE_SHOP_VARIANT_START: 'ADD_CHANGE_SHOP_VARIANT_START',
   ADD_CHANGE_SHOP_VARIANT_SUCCESS: 'ADD_CHANGE_SHOP_VARIANT_SUCCESS',
   ADD_CHANGE_SHOP_VARIANT_FAILURE: 'ADD_CHANGE_SHOP_VARIANT_FAILURE',

   DEL_SHOP_VARIANT: 'DEL_SHOP_VARIANT',
};

export default ShopActionTypes;
