const InventoryActionTypes = {
   GET_PRODUCTS_START: 'GET_PRODUCTS_START',
   GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
   GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

   ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_START:
      'ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_START',
   ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_SUCCESS:
      'ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_SUCCESS',
   ADD_CHANGE_PRODUCT_QUANTITY_TO_DKPC_SUCCESS:
      'ADD_CHANGE_PRODUCT_QUANTITY_TO_DKPC_SUCCESS',
   ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_FAILURE:
      'ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_FAILURE',

   REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_START:
      'REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_START',
   REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_SUCCESS:
      'REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_SUCCESS',
   REMOVE_PRODUCT_QUANTITY_FROM_DKPC_SUCCESS:
      'REMOVE_PRODUCT_QUANTITY_FROM_DKPC_SUCCESS',
   REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_FAILURE:
      'REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_FAILURE',

   CHANGE_PRODUCT_QUANTITY_OF_VARIANT_START:
      'CHANGE_PRODUCT_QUANTITY_OF_VARIANT_START',
   CHANGE_PRODUCT_QUANTITY_OF_VARIANT_SUCCESS:
      'CHANGE_PRODUCT_QUANTITY_OF_VARIANT_SUCCESS',
   CHANGE_PRODUCT_QUANTITY_OF_DKPC_SUCCESS:
      'CHANGE_PRODUCT_QUANTITY_OF_DKPC_SUCCESS',
   CHANGE_PRODUCT_QUANTITY_OF_VARIANT_FAILURE:
      'CHANGE_PRODUCT_QUANTITY_OF_VARIANT_FAILURE',

   ADD_CHANGE_PRODUCT_START: 'ADD_CHANGE_PRODUCT_START',
   ADD_CHANGE_PRODUCT_SUCCESS: 'ADD_CHANGE_PRODUCT_SUCCESS',
   ADD_CHANGE_PRODUCT_FAILURE: 'ADD_CHANGE_PRODUCT_FAILURE',

   TRANSFORM_EXCEL: 'TRANSFORM_EXCEL',
};

export default InventoryActionTypes;
