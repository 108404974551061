import styled from 'styled-components';

export const SignInAndSignUpContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-around;
   margin: auto;
   padding: 30px;
   border-radius: 50px;
   @media screen and (max-width: 800px) {
      flex-direction: column;
      width: unset;
      align-items: center;
   }
`;
