import InventoryActionTypes from './inventory.types';

export const getProductsStart = () => ({
   type: InventoryActionTypes.GET_PRODUCTS_START,
});

export const getProductsSuccess = (products) => ({
   type: InventoryActionTypes.GET_PRODUCTS_SUCCESS,
   payload: products,
});

export const getProductsFailure = (errorMessage) => ({
   type: InventoryActionTypes.GET_PRODUCTS_FAILURE,
   payload: errorMessage,
});

export const addChangeProductQuantityToVariantStart = (
   shopId,
   variantId,
   pQTBA
) => ({
   type: InventoryActionTypes.ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_START,
   payload: { shopId, variantId, pQTBA },
});

export const addChangeProductQuantityToVariantSuccess = (v) => ({
   type: InventoryActionTypes.ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_SUCCESS,
   payload: v,
});

export const addChangeProductQuantityToDKPCSuccess = (dkpc) => ({
   type: InventoryActionTypes.ADD_CHANGE_PRODUCT_QUANTITY_TO_DKPC_SUCCESS,
   payload: dkpc,
});

export const addChangeProductQuantityToVariantFailure = (errorMessage) => ({
   type: InventoryActionTypes.ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_FAILURE,
   payload: errorMessage,
});

export const removeProductQuantityFromVariantStart = (
   shopId,
   variantId,
   pQId
) => ({
   type: InventoryActionTypes.REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_START,
   payload: { shopId, variantId, pQId },
});

export const removeProductQuantityFromVariantSuccess = (v) => ({
   type: InventoryActionTypes.REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_SUCCESS,
   payload: v,
});

export const removeProductQuantityFromDKPCSuccess = (dkpc) => ({
   type: InventoryActionTypes.REMOVE_PRODUCT_QUANTITY_FROM_DKPC_SUCCESS,
   payload: dkpc,
});

export const removeProductQuantityFromVariantFailure = (errorMessage) => ({
   type: InventoryActionTypes.REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_FAILURE,
   payload: errorMessage,
});

export const changeProductQuantityOfVariantStart = (
   shopId,
   variantId,
   pQId,
   q
) => ({
   type: InventoryActionTypes.CHANGE_PRODUCT_QUANTITY_OF_VARIANT_START,
   payload: { shopId, variantId, pQId, q },
});

export const changeProductQuantityOfVariantSuccess = (v) => ({
   type: InventoryActionTypes.CHANGE_PRODUCT_QUANTITY_OF_VARIANT_SUCCESS,
   payload: v,
});

export const changeProductQuantityOfDKPCSuccess = (dkpc) => ({
   type: InventoryActionTypes.CHANGE_PRODUCT_QUANTITY_OF_DKPC_SUCCESS,
   payload: dkpc,
});

export const changeProductQuantityOfVariantFailure = (errorMessage) => ({
   type: InventoryActionTypes.CHANGE_PRODUCT_QUANTITY_OF_VARIANT_FAILURE,
   payload: errorMessage,
});

export const addChangeProductStart = (p, callAfter) => ({
   type: InventoryActionTypes.ADD_CHANGE_PRODUCT_START,
   payload: { p, callAfter },
});

export const addChangeProductSuccess = (product) => ({
   type: InventoryActionTypes.ADD_CHANGE_PRODUCT_SUCCESS,
   payload: product,
});

export const addChangeProductFailure = (errorMessage) => ({
   type: InventoryActionTypes.ADD_CHANGE_PRODUCT_FAILURE,
   payload: errorMessage,
});

export const transformExcel = (file) => ({
   type: InventoryActionTypes.TRANSFORM_EXCEL,
   payload: file,
});
