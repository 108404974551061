import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
   filter: drop-shadow(0px 0px 5px ${(props) => props.theme.colors.dark});

   .Toastify__toast-theme--light {
      min-height: 60px;
      font-family: 'IRANSans';
      font-size: ${(props) => props.theme.fontSizes.large};
   }

   .Toastify__toast-container {
      width: 100px;
   }

   .Toastify__toast {
   }
   .Toastify__toast-body {
      white-space: pre-line;
   }
   .Toastify__progress-bar {
   }
`;

export default StyledToastContainer;
