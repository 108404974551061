import { combineReducers } from 'redux';

//Persist
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sellerReducer from './seller/seller.reducer';
import userSellerReducer from './user-seller/user-seller.reducer';

//Reducers
import userReducer from './user/user.reducer';
import inventoryReducer from './inventory/inventory.reducer';
import shopReducer from './shop/shop.reducer';

const persistConfig = {
   key: 'root',
   storage,
   whitelist: ['user'],
};

const rootReducer = combineReducers({
   user: userReducer,
   seller: sellerReducer,
   userSeller: userSellerReducer,
   inventory: inventoryReducer,
   shop: shopReducer,
});

export default persistReducer(persistConfig, rootReducer);
