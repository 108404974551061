const fontSizes = [
   '10px',
   '12px',
   '14px',
   '16px',
   '20px',
   '24px',
   '28px',
   '32px',
   '36px',
];

fontSizes.extraSmall = fontSizes[0];
fontSizes.smaller = fontSizes[1];
fontSizes.small = fontSizes[2];
fontSizes.medium = fontSizes[3];
fontSizes.large = fontSizes[4];
fontSizes.larger = fontSizes[5];
fontSizes.xLarge = fontSizes[6];
fontSizes.xxLarge = fontSizes[7];
fontSizes.xxxLarge = fontSizes[8];

const breakPoints = ['600px', '900px', '1200px', '1800px'];

breakPoints.sm = breakPoints[0];
breakPoints.md = breakPoints[1];
breakPoints.lg = breakPoints[2];
breakPoints.xl = breakPoints[3];

const spaces = ['4px', '8px', '12px', '16px', '32px', '48px'];

spaces.smaller = spaces[0];
spaces.small = spaces[1];
spaces.medium = spaces[2];
spaces.large = spaces[3];
spaces.larger = spaces[4];
spaces.extraLarge = spaces[5];

const borderRadius = ['4px', '8px', '16px', '32px', '48px', '56px'];

borderRadius.smaller = borderRadius[0];
borderRadius.small = borderRadius[1];
borderRadius.medium = borderRadius[2];
borderRadius.large = borderRadius[3];
borderRadius.larger = borderRadius[4];
borderRadius.extraLarge = borderRadius[5];

const lineThickness = ['1px', '2px', '3px'];

lineThickness.small = lineThickness[0];
lineThickness.medium = lineThickness[1];
lineThickness.large = lineThickness[2];

const theme = {
   colors: {
      primary: '#ffffff',
      secondary: '#f37a8a',
      tertiary: '#dedfe0',
      fortiary: '#87D3E1',
      lighterDark: '#585b5e',
      dark: '#636466',
      darkGrey: '#a6a6a6',
      lightGrey: '#e6e6e6',
      light: '#FAFCFE',
      white: '#FFFFFF',
      black: '#000000',
      danger: '#ffc7b4',
      success: '#32d182',
      error: '#d1324d',
   },
   fontSizes,
   breakPoints,
   spaces,
   borderRadius,
   lineThickness,
};

export default theme;
