import UserSellerActionTypes from './user-seller.types';

export const getUserSellersStart = () => ({
   type: UserSellerActionTypes.GET_USER_SELLERS_START,
});

export const getUserSellersSuccess = (sellers) => ({
   type: UserSellerActionTypes.GET_USER_SELLERS_SUCCESS,
   payload: sellers,
});

export const getUserSellersFailure = (errorMessage) => ({
   type: UserSellerActionTypes.GET_USER_SELLERS_FAILURE,
   payload: errorMessage,
});

export const addUserSellersStart = (sellerSpecs) => ({
   type: UserSellerActionTypes.ADD_USER_SELLERS_START,
   payload: sellerSpecs,
});

export const addUserSellersSuccess = (sellerSpecs) => ({
   type: UserSellerActionTypes.ADD_USER_SELLERS_SUCCESS,
   payload: sellerSpecs,
});

export const addUserSellersFailure = (errorMessage) => ({
   type: UserSellerActionTypes.ADD_USER_SELLERS_FAILURE,
   payload: errorMessage,
});

export const delUserSeller = (errorMessage) => ({
   type: UserSellerActionTypes.DEL_USER_SELLER,
   payload: errorMessage,
});

export const toggleSellerIsKKStart = (sellerId) => ({
   type: UserSellerActionTypes.TOGGLE_SELLER_IS_KK_START,
   payload: sellerId,
});

export const toggleSellerIsKKSuccess = (seller) => ({
   type: UserSellerActionTypes.TOGGLE_SELLER_IS_KK_SUCCESS,
   payload: seller,
});

export const toggleBrandSellerIsKKStart = (brandSellerId) => ({
   type: UserSellerActionTypes.TOGGLE_BRAND_SELLER_IS_KK_START,
   payload: brandSellerId,
});

export const toggleBrandSellerIsKKSuccess = (brandSeller) => ({
   type: UserSellerActionTypes.TOGGLE_BRAND_SELLER_IS_KK_SUCCESS,
   payload: brandSeller,
});

export const setBrandSellerOffLimitStart = (brandSellerId, offLimit) => ({
   type: UserSellerActionTypes.SET_BRAND_SELLER_OFFLIMIT_START,
   payload: { brandSellerId, offLimit },
});

export const setBrandSellerOffLimitSuccess = (brandSeller) => ({
   type: UserSellerActionTypes.SET_BRAND_SELLER_OFFLIMIT_SUCCESS,
   payload: brandSeller,
});

export const toggleSellerRandomKKStart = (sellerId) => ({
   type: UserSellerActionTypes.TOGGLE_SELLER_RANDOM_KK_START,
   payload: sellerId,
});

export const toggleSellerRandomKKSuccess = (seller) => ({
   type: UserSellerActionTypes.TOGGLE_SELLER_RANDOM_KK_SUCCESS,
   payload: seller,
});
