import React from 'react';

import { defaults } from 'chart.js';

import theme from '../../utils/theme.utils';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

defaults.font.family = 'IRANSans';
ChartJS.register(
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   ArcElement
);

const PriceLineChart = ({ stateRecords }) => {
   const oneLineOptions = {
      responsive: true,
      plugins: {
         legend: {
            position: 'top',
         },
         tooltip: {
            callbacks: {
               beforeLabel: function (context) {
                  return context.formattedValue;
               },
               label: function (context) {
                  return `کک: ${
                     stateRecords[context.dataIndex].isKKOn ? '✔' : '✘'
                  }`;
               },
               afterLabel: function (context) {
                  return `آماده ارسال: ${
                     stateRecords[context.dataIndex].isAmadeErsal ? '✔' : '✘'
                  }`;
               },
            },
         },
      },
   };

   return (
      <div>
         <Line
            options={oneLineOptions}
            data={{
               labels: stateRecords
                  .sort((sR1, sR2) => new Date(sR1.id) - new Date(sR2.id))
                  .map((x) =>
                     new Date(x.createdAt).toLocaleTimeString(
                        navigator.language,
                        {
                           hour: '2-digit',
                           minute: '2-digit',
                        }
                     )
                  ),
               datasets: [
                  {
                     fill: true,
                     label: 'قیمت',
                     data: stateRecords
                        .sort((sR1, sR2) => new Date(sR1.id) - new Date(sR2.id))
                        .map((x) => x.sellingPrice / 10),
                     borderColor: theme.colors.secondary,
                     backgroundColor: theme.colors.secondary,
                     yAxisID: 'y',
                  },
               ],
            }}
         />
      </div>
   );
};

export default PriceLineChart;
