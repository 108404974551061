//Libraries
import { toast } from 'react-toastify';

//Utils
import { englishNumberToPersian } from './font.utils';

//Configs
const toastConfig = {
   position: 'top-right',
   pauseOnHover: true,
   progress: undefined,
};

export function* toastSuccess(message) {
   yield toast.success(englishNumberToPersian(message), toastConfig);
}

export function* toastError(message) {
   yield toast.error(englishNumberToPersian(message), toastConfig);
}

export function* toastMessage(message) {
   yield toast(englishNumberToPersian(message), toastConfig);
}

export function* toastWarning(message) {
   yield toast.warning(englishNumberToPersian(message), toastConfig);
}
