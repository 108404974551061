import React from 'react';
import ProductQuantityCompact from '../product-quantity-compact/product-quantity-compact.component';
import { useDispatch, useSelector } from 'react-redux';
import {
   selectIsProductsLoaded,
   selectProducts,
} from '../../redux/inventory/inventory.selectors';

import Select, { components } from 'react-select';
import selectCustomStyles from '../../utils/selectCustomStyles.utils';
import { useState } from 'react';
import {
   AddPQ,
   VariantProductsContainer,
   VariantProductsList,
   FlexRow,
} from './variant-products.styles';
import FormInput from '../form-input/form-input.component';
import { useEffect } from 'react';
import {
   addChangeProductQuantityToVariantStart,
   getProductsStart,
} from '../../redux/inventory/inventory.actions';
import CustomButton from '../custom-button/custom-button.component';

const VariantProducts = ({ shopId, variantId, productQuantities }) => {
   const dispatch = useDispatch();
   const allProducts = useSelector(selectProducts);
   const isProductsLoaded = useSelector(selectIsProductsLoaded);
   // console.log('allProducts', allProducts);
   const [productQuantityToBeAdded, setProductQuantityToBeAdded] = useState({
      productId: '',
      quantity: 1,
   });

   // useEffect(() => {
   //    dispatch(getProductsStart());
   // }, []);

   const { Option } = components;
   const IconOption = (props) => (
      <Option {...props}>
         <FlexRow>
            <img
               src={props.data.icon}
               style={{ width: 36 }}
               alt={props.data.label}
            />
            {props.data.label}
         </FlexRow>
      </Option>
   );

   return (
      <VariantProductsContainer>
         {isProductsLoaded && (
            <AddPQ>
               <Select
                  styles={selectCustomStyles}
                  onChange={(e) =>
                     setProductQuantityToBeAdded({
                        ...productQuantityToBeAdded,
                        productId: e.value,
                     })
                  }
                  placeholder='*کالا'
                  options={allProducts.map((p) => ({
                     value: p.id,
                     label: p.name,
                     icon: p.imageLink,
                  }))}
                  components={{ Option: IconOption }}
               />
               <FormInput
                  label='تعداد'
                  handleChange={(e) =>
                     setProductQuantityToBeAdded({
                        ...productQuantityToBeAdded,
                        quantity: e.target.value,
                     })
                  }
                  type='text'
                  value={productQuantityToBeAdded.quantity}
                  required
               />
               <CustomButton
                  onClick={() =>
                     dispatch(
                        addChangeProductQuantityToVariantStart(
                           shopId,
                           variantId,
                           productQuantityToBeAdded
                        )
                     )
                  }
               >
                  اضافه کردن
               </CustomButton>
            </AddPQ>
         )}
         <VariantProductsList>
            {productQuantities
               .sort((pQ1, pQ2) => pQ2.quantity - pQ1.quantity)
               .map((pQ) => (
                  <ProductQuantityCompact
                     shopId={shopId}
                     variantId={variantId}
                     productQuantity={pQ}
                     key={pQ.id}
                  />
               ))}
         </VariantProductsList>
      </VariantProductsContainer>
   );
};

export default VariantProducts;
