import React, { useEffect, useState } from 'react';

//Router Hooks
import { useParams, useNavigate, useLocation } from 'react-router-dom';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Styles
import Spinner from '../../components/spinner/spinner.component';
import {
   selectIsSellerDKPCsLoaded,
   selectIsSellerOrdersLoaded,
   selectSellerDKPCs,
   selectSellerOrders,
} from '../../redux/seller/seller.selectors';
import {
   getExcelStart,
   getSellerDKPCsStart,
   getSellerOrdersStart,
} from '../../redux/seller/seller.actions';
import DKPCInput from '../../components/dkpc-input/dkpc-input.component';
import {
   SellerPageContainer,
   SellerDKPCList,
   AddDKPCButton,
   SellerSideBar,
   SellerTitle,
   Filters,
   BrandsListButton,
   ExcelButton,
} from './seller-orders.styles';
import Popup from 'reactjs-popup';
import DKPCSummary from '../../components/dkpc-summary/dkpc-summary.component';
import SearchBar from '../../components/search-bar/search-bar.components';
import { persianNumberToEnglish } from '../../utils/font.utils';
import CheckboxInput from '../../components/checkbox-input/checkbox-input.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
   getUserSellersStart,
   toggleSellerRandomKKStart,
} from '../../redux/user-seller/user-seller.actions';
import { selectUserSellers } from '../../redux/user-seller/user-seller.selectors';
import { getPercent } from '../../utils/math.utils';
import BatchSortRadiobox from '../../components/batch-sort-radiobox/batch-sort-radiobox.component';
import DKPCOrderSummary from '../../components/dkpc-order-summary/dkpc-order-summary.component';

function turn0ToInfinity(x) {
   return x === 0 ? Infinity : x;
}

const allPosibilities = [
   {
      sort_id: 0,
      name: 'جدیدترین',
      func: (dkpc1, dkpc2) => (dkpc1, dkpc2) =>
         new Date(dkpc2.lastOrderTime) - new Date(dkpc1.lastOrderTime),
   },
   {
      sort_id: 1,
      name: 'بیشترین حجم موجودی',
      func: (dkpc1, dkpc2) => (dkpc1, dkpc2) =>
         dkpc2.sellingStock * dkpc2.buyingPrice -
         dkpc1.sellingStock * dkpc1.buyingPrice,
   },
   {
      sort_id: 2,
      name: 'بیشترین تعداد موجودی',
      func: (dkpc1, dkpc2) => (dkpc1, dkpc2) =>
         dkpc2.sellingStock - dkpc1.sellingStock,
   },
   {
      sort_id: 3,
      name: 'بیشترین تعداد تغییر قیمت',
      func: (dkpc1, dkpc2) => (dkpc1, dkpc2) =>
         dkpc2.stateRecords.length - dkpc1.stateRecords.length,
   },
   {
      sort_id: 4,
      name: 'رزرو دیجی',
      func: (dkpc1, dkpc2) => (dkpc1, dkpc2) =>
         dkpc2.digikalaReservedStock - dkpc1.digikalaReservedStock,
   },
   {
      sort_id: 5,
      name: 'استعداد',
      func: (dkpc1, dkpc2) => (dkpc1, dkpc2) =>
         dkpc2.digikalaReservedStock -
         dkpc2.digikalaWarehouseStock -
         (dkpc1.digikalaReservedStock - dkpc1.digikalaWarehouseStock),
   },
];

export const SellerOrdersPage = () => {
   const dispatch = useDispatch();
   const isOrderDKPCsLoaded = useSelector(selectIsSellerOrdersLoaded);
   const ordersDKPC = useSelector(selectSellerOrders);
   const params = useParams();
   const currentUser = useSelector(selectCurrentUser);
   const sellers = useSelector(selectUserSellers);
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      if (!currentUser) {
         navigate('/');
      }
      if (currentUser) {
         dispatch(getUserSellersStart());
      }
   }, []);

   // console.log('isLoaded', isLoaded);
   // console.log('params', params);

   const [isAddDKPCPopUpOpen, setIsAddDKPCPopUpOpen] = useState(false);

   const [withAnbaresh, setWithAnbaresh] = useState(false);

   const [noAnbaresh, setNoAnbaresh] = useState(false);

   const [notGhahraman, setNotGhahraman] = useState(false);

   const [activeAndNotAmadeErsal, setActiveAndNotAmadeErsal] = useState(false);

   const [noStock, setNoStock] = useState(false);

   const [discountOverLimit, setDiscountOverLimit] = useState(false);

   const [underLimit, setUnderLimit] = useState(false);

   const [estedadAnbaresh, setEstedadAnbaresh] = useState(false);

   // useEffect(() => {
   //    if (sellers) {
   //       setRandomKK(
   //          sellers.find((seller) => seller.id === Number(params.sellerId))
   //             .randomKK
   //       );
   //    }
   // }, [sellers]);

   const [kk, setKK] = useState(false);

   const [query, setQuery] = useState('');
   useEffect(() => {
      dispatch(getSellerOrdersStart(params.sellerId));
   }, [dispatch, params.sellerId]);

   const handleSearchChange = (event) => {
      const { value } = event.target;

      setQuery(persianNumberToEnglish(value));
   };

   const [sortFunction, setSortFunction] = useState(
      (obj1, obj2) => (obj1, obj2) => obj1.id - obj2.id
   );

   const handleSort = (sortfunc) => {
      setSortFunction(sortfunc);
   };

   // console.log('ordersDKPC', ordersDKPC);

   const getFilteredDKPCs = () => {
      const filtered = ordersDKPC
         .sort((dkpc1, dkpc2) => sortFunction(dkpc1, dkpc2))
         .filter(
            (dkpc) =>
               persianNumberToEnglish(query.toLowerCase())
                  .split(' ')
                  .some((s) => dkpc.name.toLowerCase().includes(s)) ||
               persianNumberToEnglish(query.toLowerCase())
                  .split(' ')
                  .some((s) => dkpc.dkp.toLowerCase().includes(s)) ||
               persianNumberToEnglish(query.toLowerCase())
                  .split(' ')
                  .some((s) => dkpc.dkpcNum.toLowerCase().includes(s))
         )
         .filter((dkpc) =>
            kk
               ? (dkpc.seller.isKKOn ||
                    dkpc.isKKOn ||
                    dkpc.brandSeller.isKKOn) &&
                 dkpc.brandSeller.brandOffLimit < 99
               : true
         )
         .filter((dkpc) =>
            notGhahraman
               ? !dkpc.isBuyBoxWinner && dkpc.isActive && dkpc.sellingStock > 0
               : true
         )
         .filter((dkpc) => (withAnbaresh ? dkpc.anbaresh > 0 : true))
         .filter((dkpc) => (estedadAnbaresh ? dkpc.estedadAnbaresh : true))
         .filter((dkpc) => (noAnbaresh ? dkpc.anbaresh === 0 : true))
         .filter((dkpc) =>
            activeAndNotAmadeErsal
               ? dkpc.isActive && dkpc.sellingStock > 0 && !dkpc.isAmadeErsal
               : true
         )
         .filter((dkpc) => (noStock ? dkpc.sellingStock === 0 : true))
         .filter((dkpc) =>
            discountOverLimit
               ? dkpc.name.includes('مجموعه') ||
                 dkpc.name.includes('به همراه') ||
                 (dkpc.name.includes('بادی') && dkpc.name.includes(' و '))
                  ? dkpc.discountPercent > dkpc.brandSeller.brandOffLimit + 5
                  : dkpc.discountPercent > dkpc.brandSeller.brandOffLimit
               : true
         )

         .filter((dkpc) =>
            underLimit ? dkpc.sellingPrice / 10 < dkpc.leastPrice : true
         );

      return !!query && query.length > 1 ? filtered : filtered.slice(0, 150);
   };

   // console.log('ordersDKPC', ordersDKPC);
   return currentUser && isOrderDKPCsLoaded ? (
      <div>
         {ordersDKPC.length > 0 ? (
            <SellerPageContainer>
               <SellerSideBar>
                  <SellerTitle>
                     سفارش{' '}
                     {sellers &&
                        sellers.find(
                           (seller) => seller.id === Number(params.sellerId)
                        ).name}
                  </SellerTitle>

                  <SearchBar
                     placeHolder='جستجو'
                     handleChange={handleSearchChange}
                  ></SearchBar>
                  <Filters>
                     <CheckboxInput
                        label={'دارای انبارش'}
                        checked={withAnbaresh}
                        handleChange={() => setWithAnbaresh(!withAnbaresh)}
                     />
                     <CheckboxInput
                        label={'بدون انبارش'}
                        checked={noAnbaresh}
                        handleChange={() => setNoAnbaresh(!noAnbaresh)}
                     />
                     <CheckboxInput
                        label={'استعداد انبارش'}
                        checked={estedadAnbaresh}
                        handleChange={() =>
                           setEstedadAnbaresh(!estedadAnbaresh)
                        }
                     />

                     {currentUser.isKK && (
                        <CheckboxInput
                           label={'همه کک'}
                           checked={kk}
                           handleChange={() => setKK(!kk)}
                        />
                     )}
                     <CheckboxInput
                        label={'بدون بای‌باکس، فعال و دارای موجودی'}
                        checked={notGhahraman}
                        handleChange={() => setNotGhahraman(!notGhahraman)}
                     />

                     <CheckboxInput
                        label={'فعال، دارای موجودی و بدون آماده ارسال'}
                        checked={activeAndNotAmadeErsal}
                        handleChange={() =>
                           setActiveAndNotAmadeErsal(!activeAndNotAmadeErsal)
                        }
                     />
                     <CheckboxInput
                        label={'بدون موجودی'}
                        checked={noStock}
                        handleChange={() => setNoStock(!noStock)}
                     />

                     {currentUser.isKK && (
                        <CheckboxInput
                           label={'تخفیف بیشتر از مجاز'}
                           checked={discountOverLimit}
                           handleChange={() =>
                              setDiscountOverLimit(!discountOverLimit)
                           }
                        />
                     )}

                     {currentUser.isKK && (
                        <CheckboxInput
                           label={'زیر قیمت'}
                           checked={underLimit}
                           handleChange={() => setUnderLimit(!underLimit)}
                        />
                     )}
                  </Filters>
                  <AddDKPCButton
                     isBlue
                     onClick={() => setIsAddDKPCPopUpOpen(true)}
                  >
                     اضافه کردن تنوع
                  </AddDKPCButton>
                  <ExcelButton
                     isGreen
                     onClick={() => dispatch(getExcelStart(params.sellerId))}
                  >
                     دریافت اکسل
                  </ExcelButton>
                  {currentUser && currentUser.isKK && (
                     <BrandsListButton
                        isRed
                        onClick={() => navigate(location.pathname + '/brands')}
                     >
                        مدیریت برندها
                     </BrandsListButton>
                  )}
                  <div>تعداد: {getFilteredDKPCs().length}</div>
                  <div>
                     درصد بای‌باکس ها:{' '}
                     {getPercent(
                        getFilteredDKPCs().reduce(
                           (buyBoxWinnerCount, dkpc) =>
                              dkpc.isBuyBoxWinner
                                 ? buyBoxWinnerCount + 1
                                 : buyBoxWinnerCount,
                           0
                        ) / getFilteredDKPCs().length
                     )}
                  </div>
               </SellerSideBar>
               <SellerDKPCList>
                  <BatchSortRadiobox
                     handleSort={(sortFunction) => handleSort(sortFunction)}
                     allPosibilities={allPosibilities}
                  />
                  {getFilteredDKPCs().map((dkpc) => (
                     <DKPCOrderSummary
                        dkpc={dkpc}
                        key={dkpc.id}
                        sellerId={params.sellerId}
                     />
                  ))}
               </SellerDKPCList>
            </SellerPageContainer>
         ) : (
            <div>
               <SellerTitle>
                  {sellers &&
                     sellers.find(
                        (seller) => seller.id === Number(params.sellerId)
                     ).name}
               </SellerTitle>
               <AddDKPCButton
                  isBlue
                  onClick={() => setIsAddDKPCPopUpOpen(true)}
               >
                  اضافه کردن تنوع
               </AddDKPCButton>
               <Spinner />
            </div>
         )}
         {isAddDKPCPopUpOpen && (
            <Popup
               modal
               open={isAddDKPCPopUpOpen}
               onClose={() => setIsAddDKPCPopUpOpen(false)}
            >
               <DKPCInput
                  setIsPopUpOpen={setIsAddDKPCPopUpOpen}
                  sellerId={params.sellerId}
               />
            </Popup>
         )}
      </div>
   ) : (
      <Spinner />
   );
};

export default SellerOrdersPage;
