import styled, { css } from 'styled-components';

const buttonStyles = css`
   background-color: ${(props) => props.theme.colors.dark};
   color: white;
   border: 1px solid ${(props) => props.theme.colors.dark}00;

   &:hover:enabled {
      background-color: ${(props) => props.theme.colors.tertiary};
      color: ${(props) => props.theme.colors.dark};
      border: 1px solid ${(props) => props.theme.colors.dark};
   }
`;

const invertedButtonStyles = css`
   background-color: ${(props) => props.theme.colors.tertiary};
   color: #131b26;
   border: 1px solid ${(props) => props.theme.colors.dark};

   &:hover:enabled {
      background-color: ${(props) => props.theme.colors.dark};
      color: ${(props) => props.theme.colors.primary};
      border: 1px solid ${(props) => props.theme.colors.dark}00;
   }
`;

const googleSignInStyles = css`
   background-color: ${(props) => props.theme.colors.fortiary};
   border: 1px solid ${(props) => props.theme.colors.darkGrey};
   color: black;

   &:hover:enabled {
      border: 1px solid ${(props) => props.theme.colors.darkGrey}00;
   }
`;

const greenStyle = css`
   background-color: #017e77;
   min-width: 100px;
   color: white;

   &:hover:enabled {
      background-color: white;
      color: #017e77;
      border: 1px solid #017e77;
   }
`;

const redStyle = css`
   background-color: #b80046;
   min-width: 100px;

   color: white;

   &:hover:enabled {
      background-color: white;
      color: #b80046;
      border: 1px solid #b80046;
   }
`;

const getButtonStyles = (props) => {
   if (props.isBlue) {
      return googleSignInStyles;
   } else if (props.isGreen) {
      return greenStyle;
   } else if (props.isRed) {
      return redStyle;
   }

   return props.inverted ? invertedButtonStyles : buttonStyles;
};

export const CustomButtonContainer = styled.button`
   min-width: ${(props) => (props.isSmall ? '30px' : '125px')};
   width: ${(props) => (props.width ? props.width : 'auto')};
   height: 50px;
   letter-spacing: 0.5px;
   line-height: 50px;
   padding: 0 15px 0 15px;
   margin: 5px;
   font-size: ${(props) => props.theme.fontSizes.medium};
   cursor: pointer;
   display: flex;
   justify-content: center;

   ${getButtonStyles}

   &:disabled {
      background-color: ${(props) => props.theme.colors.tertiary};
      cursor: default;
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      min-width: ${(props) => (props.isSmall ? '50px' : '125px')};
      padding: 0 5px 0 5px;
      font-size: ${(props) => props.theme.fontSizes.small};
   }
`;
