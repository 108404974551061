import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
   [selectUser],
   (user) => user.currentUser
);

export const selectIsUserAuthenticated = createSelector(
   [selectUser],
   (user) => !!user.currentUser
);

export const selectUserToken = createSelector(
   [selectCurrentUser],
   (currentUser) => currentUser.token
);

export const selectUserAuthStep = createSelector(
   [selectUser],
   (user) => user.authStep
);

export const selectUserPhone = createSelector(
   [selectUser],
   (user) => user.phone
);
