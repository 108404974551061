import { takeLatest, put, all, call, select } from 'redux-saga/effects';

//Libraries
import axios from 'axios';

import ShopActionTypes from './shop.types';

//Utils
import { selectUserToken } from '../user/user.selectors';

import { toastError, toastSuccess } from '../../utils/toast-redux.utils';
import {
   addChangeShopVariantFailure,
   addChangeShopVariantSuccess,
   delShopVariant,
   getShopFailure,
   getShopSuccess,
} from './shop.actions';

export function* getShopSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };

      const response = yield axios.get(`/shop/${payload}/`, config);
      // console.log('shop', response);
      yield put(getShopSuccess(response.data));
   } catch (error) {
      yield put(getShopFailure(error.response.data.message));
   }
}

export function* addChangeShopVariantSaga({
   payload: { shopId, v, closePopup },
}) {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post('/shop/' + shopId + '/add/', v, config);
      yield put(addChangeShopVariantSuccess(response.data));
      yield closePopup();
   } catch (error) {
      yield put(addChangeShopVariantFailure(error.response.data.message));
   }
}

export function* delShopVariantSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      // console.log('dkpc-pq1', payload);
      const response = yield axios.delete('/shop/variant-pq/', {
         data: payload,
         ...config,
      });
      // console.log('dkpc-pq2', response);
      yield put(delShopVariant(response.data));
   } catch (error) {
      // yield put(
      //    removeProductQuantityFromVariantFailure(error.response.data.message)
      // );
   }
}

export function* onGetShopStart() {
   yield takeLatest(ShopActionTypes.GET_SHOP_START, getShopSaga);
}

export function* onAddChangeShopVariantStart() {
   yield takeLatest(
      ShopActionTypes.ADD_CHANGE_SHOP_VARIANT_START,
      addChangeShopVariantSaga
   );
}
export function* onDelShopVariantStart() {
   yield takeLatest(ShopActionTypes.DEL_SHOP_VARIANT, delShopVariantSaga);
}

// export function* onChangeProductQuantityStart() {
//    yield takeLatest(
//       ShopActionTypes.CHANGE_PRODUCT_QUANTITY_OF_DKPC_START,
//       changeProductQuantityOfDKPCSaga
//    );
// }

// export function* onAddChangeProductStart() {
//    yield takeLatest(
//       ShopActionTypes.ADD_CHANGE_PRODUCT_START,
//       addChangeProductSaga
//    );
// }

export function* shopSagas() {
   yield all([
      call(onGetShopStart),
      call(onAddChangeShopVariantStart),
      call(onDelShopVariantStart),
   ]);
}
