import { takeLatest, put, all, call } from 'redux-saga/effects';

//Libraries
import axios from 'axios';

import UserActionTypes from './user.types';

//Redux Actions
import { signInSuccess, signInFailure } from './user.actions';

//Utils
import { toastError, toastSuccess } from '../../utils/toast-redux.utils';

export function* phoneLoginBackendSaga({ payload: { password, phone } }) {
   console.log({ password, phone });

   try {
      const config = {
         headers: {
            Content_type: 'application/json',
         },
      };
      const response = yield axios.post(
         '/users/login/',
         {
            phone: phone,
            password: password,
         },
         config
      );

      if (response.status === 200) {
         yield put(signInSuccess(response.data));
      } else {
         yield put(signInFailure(response.data.message));
      }
   } catch (error) {
      yield put(signInFailure(error.response.data.message));
   }
}

export function* toastFailure({ payload: message }) {
   yield toastError(message);
}

export function* toastSuccessAndGoBack({
   payload: {
      data: { message },
   },
}) {
   yield toastSuccess(message);
}

export function* onPhoneLoginBackend() {
   yield takeLatest(UserActionTypes.PHONE_LOGIN_BACKEND, phoneLoginBackendSaga);
}

export function* onSigInFailure() {
   yield takeLatest(UserActionTypes.SIGN_IN_FAILURE, toastFailure);
}

export function* onSigInSuccess() {
   yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, toastSuccessAndGoBack);
}

export function* userSagas() {
   yield all([
      call(onPhoneLoginBackend),
      call(onSigInFailure),
      call(onSigInSuccess),
   ]);
}
