import React, { useState } from 'react';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Redux Actions

//Redux Selectors

//Components
import CustomButton from '../custom-button/custom-button.component';
import FormInput from '../form-input/form-input.component';

//Styles
import {
   DKPCInputContainer,
   ButtonsBarContainer,
   FormContainer,
   FormGroupContainer,
   SectionTitle,
} from './dkpc-input.styles';
import { addSellerDKPCStart } from '../../redux/seller/seller.actions';
import {
   getPersianCurrency,
   persianNumberToEnglish,
} from '../../utils/font.utils';
import AddChangeProduct from '../add-change-product/add-change-product.component';
import VariantProducts from '../variant-products/variant-products.component';

const DKPCInput = ({ setIsPopUpOpen, sellerId }) => {
   const dispatch = useDispatch();
   const [dkpc, setDKPC] = useState({
      dgLink: '',
      rang: '',
      leastPrice: '',
      mostPrice: '',
      buyingPrice: '',
      packingPrice: '',
      // diffPrice: 100,
      // timeCoef: 5,
   });

   const { dgLink, leastPrice, mostPrice, buyingPrice, packingPrice, rang } =
      dkpc;

   const handleSubmit = async (event) => {
      event.preventDefault();
      // console.log('addSellerDKPCStart');
      dispatch(
         addSellerDKPCStart(
            sellerId,
            {
               dgLink: dgLink,
               rang: rang,
               leastPrice: persianNumberToEnglish(
                  leastPrice.replaceAll(',', '')
               ),
               mostPrice: persianNumberToEnglish(mostPrice.replaceAll(',', '')),
               buyingPrice: persianNumberToEnglish(
                  buyingPrice.replaceAll(',', '')
               ),
               packingPrice: persianNumberToEnglish(
                  packingPrice.replaceAll(',', '')
               ),
            },
            () => setIsPopUpOpen(false)
         )
      );
   };

   const getApproxProfit = (buyPrice, engProfit) => {
      const engCommission = 8;

      const cheapFormula = Math.ceil(
         ((1 + engProfit / 100) * buyPrice + (70000 / 10) * 1.09) /
            (1 - (engCommission / 100) * 1.09)
      );
      const expensiveFormula = Math.ceil(
         ((1 + engProfit / 100) * buyPrice + (0 / 10) * 1.09) /
            (1 - ((engCommission + 7) / 100) * 1.09)
      );
      const finalSellPrice =
         cheapFormula < 100000 ? cheapFormula : expensiveFormula;

      return Math.ceil(finalSellPrice / 100) * 100;
   };

   const handleChange = (event) => {
      const { value, name } = event.target;

      // console.log(name);

      // console.log(
      //    name,
      //    value,
      //    getApproxProfit(
      //       Number(persianNumberToEnglish(value.replaceAll(',', ''))) +
      //          Number(persianNumberToEnglish(packingPrice.replaceAll(',', ''))),
      //       7
      //    ),
      //    Number(persianNumberToEnglish(value.replaceAll(',', ''))) +
      //       Number(persianNumberToEnglish(packingPrice.replaceAll(',', '')))
      // );
      if (name === 'buyingPrice') {
         setDKPC({
            ...dkpc,
            leastPrice: getPersianCurrency(
               getApproxProfit(
                  Number(persianNumberToEnglish(value.replaceAll(',', ''))) +
                     Number(
                        persianNumberToEnglish(packingPrice.replaceAll(',', ''))
                     ),
                  7
               )
            ),
            buyingPrice: getPersianCurrency(value),
         });
      } else if (name === 'packingPrice') {
         setDKPC({
            ...dkpc,
            leastPrice: getPersianCurrency(
               getApproxProfit(
                  Number(persianNumberToEnglish(value.replaceAll(',', ''))) +
                     Number(
                        persianNumberToEnglish(buyingPrice.replaceAll(',', ''))
                     ),
                  7
               )
            ),
            packingPrice: getPersianCurrency(value),
         });
      } else {
         setDKPC({
            ...dkpc,
            [name]: name.toLowerCase().includes('price')
               ? getPersianCurrency(value)
               : value,
         });
      }
   };

   return (
      <DKPCInputContainer>
         <SectionTitle>مشخصات کالا را وارد کنید</SectionTitle>
         <form onSubmit={handleSubmit}>
            <FormContainer>
               <FormInput
                  className='two'
                  name='dgLink'
                  type='text'
                  handleChange={handleChange}
                  value={dgLink}
                  label='لینک کالا'
                  required
               />
               <FormInput
                  className='one'
                  name='rang'
                  type='text'
                  handleChange={handleChange}
                  value={rang}
                  label='رنگ'
               />

               <FormGroupContainer>
                  <FormInput
                     name='buyingPrice'
                     type='text'
                     handleChange={handleChange}
                     value={buyingPrice}
                     label='قیمت خرید'
                     required
                  />
                  <FormInput
                     name='packingPrice'
                     type='text'
                     handleChange={handleChange}
                     value={packingPrice}
                     label='قیمت بسته بندی'
                     required
                  />
                  <FormInput
                     name='leastPrice'
                     type='text'
                     handleChange={handleChange}
                     value={leastPrice}
                     label='حداقل قیمت'
                     subLabel={buyingPrice ? 'پیشنهاد ک۸ س۷' : ''}
                     required
                  />
                  <FormInput
                     name='mostPrice'
                     type='text'
                     handleChange={handleChange}
                     value={mostPrice}
                     label='حداکثر قیمت'
                     required
                  />
               </FormGroupContainer>
               <FormGroupContainer>
                  {
                     // <FormInput
                     //    name='diffPrice'
                     //    type='text'
                     //    handleChange={handleChange}
                     //    value={diffPrice}
                     //    label='پله کاهش قیمت'
                     //    required
                     // />
                  }
                  {
                     // <FormInput
                     //    name='timeCoef'
                     //    type='text'
                     //    handleChange={handleChange}
                     //    value={timeCoef}
                     //    label='ضریب زمانی'
                     //    required
                     // />
                  }
               </FormGroupContainer>
            </FormContainer>
            <ButtonsBarContainer>
               <CustomButton
                  type='submit'
                  disabled={Object.keys(dkpc)
                     .filter((w) => w !== 'rang')
                     .map((k) => dkpc[k])
                     .some((v) => v === '')}
               >
                  {' '}
                  ثبت تنوع{' '}
               </CustomButton>
            </ButtonsBarContainer>
         </form>
      </DKPCInputContainer>
   );
};

export default DKPCInput;
