import styled from 'styled-components';
import CustomButton from '../custom-button/custom-button.component';

export const AddChangeProductButton = styled(CustomButton)`
   margin: 20px auto;
`;

export const ProductInputContainer = styled.div`
   width: 100%;
   direction: rtl;
   padding: 30px;

   max-height: 90vh;
   overflow: auto;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      padding: 10px;
   }
`;

export const ButtonsBarContainer = styled.div`
   margin-top: 25px;
   display: flex;
   justify-content: space-between;
`;

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .one {
      width: 200px;
   }
   .two {
      width: 500px;
   }
`;

export const RtLContainer = styled.div`
   direction: rtl;
`;
export const SectionTitle = styled.div`
   padding: 20px 0px;

   font-size: ${(props) => props.theme.fontSizes.medium};
`;

export const FormGroupContainer = styled.div`
   display: flex;
   flex-direction: column;

   gap: 30px;
   justify-content: space-between;
`;
