import styled, { css } from 'styled-components';
import theme from '../../utils/theme.utils';
const subColor = theme.colors.darkGrey;
const mainColor = theme.colors.dark;

export const GroupContainer = styled.div`
   /* margin: 10px 0px 60px 0px; */
   display: flex;
   flex-direction: column;
`;

export const InputContainer = styled.div`
   display: flex;
   align-items: center;
   flex-direction: row-reverse;
`;

export const FileInputContainer = styled.input`
   display: none;
   background: none;
   background-color: ${theme.colors.primary};
   color: ${(props) => (props.disabled ? subColor : mainColor)};
   font-size: ${(props) => props.theme.fontSizes.large};
   padding: 10px 10px 10px 5px;
   /* display: block; */
   width: 100%;
   border: none;
   border-radius: 0;
   border-bottom: 1px solid ${subColor};

   :-webkit-autofill,
   :-webkit-autofill:hover,
   :-webkit-autofill:focus,
   :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.tertiary} inset !important;
      box-shadow: 0 0 0 30px ${theme.colors.tertiary} inset !important;
   }

   &:focus {
      outline: none;
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      font-size: ${(props) => props.theme.fontSizes.medium};
   }
`;

export const Label = styled.h3`
   color: ${mainColor};
   font-size: ${(props) => props.theme.fontSizes.medium};
   font-weight: normal;
   pointer-events: none;
   transition: 300ms ease all;
   margin: 10px;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      font-size: ${(props) => props.theme.fontSizes.small};
   }
`;

export const FileInputName = styled.h3`
   color: ${mainColor};
   font-size: ${(props) => props.theme.fontSizes.medium};
   font-weight: normal;
   pointer-events: none;
   transition: 300ms ease all;
   margin: 10px;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      font-size: ${(props) => props.theme.fontSizes.small};
   }
`;

export const RequiredStar = styled.span`
   color: ${(props) => props.theme.colors.error};
`;
