import React, { useEffect, useState } from 'react';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Components
import CustomButton from '../custom-button/custom-button.component';
import FormInput from '../form-input/form-input.component';

//Utils
import {
   getPersianPositiveNumbers,
   getPersianCurrency,
   persianNumberToEnglish,
} from '../../utils/font.utils';
import { toastWarning } from '../../utils/toast.utils';

//Styles
import {
   DKPCContainer,
   ImageContainer,
   NameContainer,
   PriceContainer,
   PriceContainerWide,
   DKPCFormContainer,
   RemoveButtonContainer,
   DigiLink,
   IsKKContainer,
   StockContainer,
   AvgMonthSelling,
   EstimatedSellingTime,
   ActiveAndRemoveButtons,
   PriceContainerNarrow,
   ImageAndNameContainer,
} from './dkpc-order-summary.styles';
import {
   checkSellerDKPC,
   checkSellerDKPCOrder,
   delSellerDKPC,
   setSellerDKPCStart,
   toggleSellerDKPCIsActiveBotStart,
   toggleSellerDKPCIsKKOnStart,
} from '../../redux/seller/seller.actions';
import AcknowledgeAction from '../acknowledge-action/acknowledge-action.component';
import CheckboxInput from '../checkbox-input/checkbox-input.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import Popup from 'reactjs-popup';
import PriceLineChart from '../price-line-chart/price-line-chart.component';

const DKPCOrderSummary = ({ dkpc, sellerId }) => {
   const dispatch = useDispatch();

   const currentUser = useSelector(selectCurrentUser);

   const [isDelPopupOpen, setIsDelPopupOpen] = useState(false);

   const [isPriceChartPopupOpen, setIsPriceChartPopupOpen] = useState(false);

   const [anyChange, setAnyChange] = useState(false);
   const [dkpcSpecs, setDKPCSpecs] = useState({
      mostPrice: '',
      // diffPrice: '',
      // timeCoef: '',
      leastPrice: '',
      buyingPrice: '',
      packingPrice: '',
   });

   const { mostPrice, leastPrice, buyingPrice, packingPrice } = dkpcSpecs;

   useEffect(() => {
      setDKPCSpecs({
         ...dkpcSpecs,
         mostPrice: getPersianCurrency(dkpc.mostPrice),
         // diffPrice: getPersianCurrency(dkpc.diffPrice),
         // timeCoef: getPersianPositiveNumbers(dkpc.timeCoef),
         leastPrice: getPersianCurrency(dkpc.leastPrice),
         buyingPrice: getPersianCurrency(dkpc.buyingPrice),
         packingPrice: getPersianCurrency(dkpc.packingPrice),
      });
   }, []);

   const handleSubmit = async (event) => {
      event.preventDefault();
      // console.log(dkpcSpecs);
      const englishDKPCSpecs = Object.fromEntries(
         Object.entries(dkpcSpecs).map(([k, v]) => [
            k,
            persianNumberToEnglish(v.replaceAll(',', '')),
         ])
      );
      // console.log(englishDKPCSpecs);
      const isValid = validateProductInventorySpecs(englishDKPCSpecs);

      if (isValid === true) {
         setAnyChange(false);
         dispatch(
            setSellerDKPCStart(
               {
                  dkpcId: dkpc.id,
                  ...englishDKPCSpecs,
               },
               sellerId
            )
         );
      }
   };

   const handleChange = (event) => {
      const { value, name } = event.target;
      setAnyChange(true);

      setDKPCSpecs({
         ...dkpcSpecs,

         [name]: name.toLowerCase().includes('price')
            ? getPersianCurrency(value)
            : getPersianPositiveNumbers(value),
      });
   };

   const getApproxProfit = (engProfit) => {
      const numBuyPrice = Number(
         persianNumberToEnglish(buyingPrice.replaceAll(',', ''))
      );
      const numPackPrice = Number(
         persianNumberToEnglish(packingPrice.replaceAll(',', ''))
      );

      const engCommission = dkpc.commission;

      const cheapFormula = Math.ceil(
         ((1 + engProfit / 100) * (numBuyPrice + numPackPrice) +
            (70000 / 10) * 1.09) /
            (1 - (engCommission / 100) * 1.09)
      );
      const expensiveFormula = Math.ceil(
         ((1 + engProfit / 100) * (numBuyPrice + numPackPrice) +
            (0 / 10) * 1.09) /
            (1 - ((engCommission + 7) / 100) * 1.09)
      );
      const finalSellPrice =
         cheapFormula < 100000 ? cheapFormula : expensiveFormula;

      return Math.ceil(finalSellPrice / 100) * 100;
   };

   const getHajmString = () => {
      const engBuyingPrice = persianNumberToEnglish(
         buyingPrice.replaceAll(',', '')
      );
      const hajm = dkpc.sellingStock * engBuyingPrice;

      return hajm > 1000000
         ? ` ح ${getPersianCurrency(Math.floor(hajm / 100000) / 10)}م`
         : ` ح ${getPersianCurrency(Math.floor(hajm / 1000))}هزار`;
   };

   return (
      <DKPCContainer>
         <ImageAndNameContainer>
            {currentUser.isKK ? (
               <ImageContainer
                  href={`https://seller.digikala.com/pwa/variant-management?filterSearchIn=search_term&filterSearchQuery=${dkpc.dkpcNum}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  estedadAnbaresh={dkpc.estedadAnbaresh}
                  withProducts={
                     dkpc.productQuantities && dkpc.productQuantities.length > 0
                  }
               >
                  <img src={dkpc.imageLink} alt='item' />
               </ImageContainer>
            ) : (
               <ImageContainer>
                  <img src={dkpc.imageLink} alt='item' />
               </ImageContainer>
            )}

            <DigiLink
               href={dkpc.dgLink}
               target='_blank'
               rel='noopener noreferrer'
            >
               <NameContainer isBuyBox={dkpc.isBuyBoxWinner}>
                  {dkpc.name} | {dkpc.rang}
               </NameContainer>
            </DigiLink>
         </ImageAndNameContainer>
         <StockContainer>
            <div>م د: {dkpc.digikalaWarehouseStock}</div>
            <div>ر د: {dkpc.digikalaReservedStock}</div>
            <div>م: {dkpc.sellerWarehouseStock}</div>
            <div>ر: {dkpc.sellerReservedStock}</div>
            <div>ا: {dkpc.anbaresh}</div>
         </StockContainer>
         <form onSubmit={handleSubmit}>
            <DKPCFormContainer>
               <PriceContainer>
                  <FormInput
                     name='leastPrice'
                     type='text'
                     handleChange={handleChange}
                     value={leastPrice}
                     label='حداقل'
                     subLabel={
                        buyingPrice !== '۰'
                           ? `ک${dkpc.commission} س۱۰ ${getPersianCurrency(
                                getApproxProfit(10)
                             )} س۷ ${getPersianCurrency(getApproxProfit(7))}`
                           : ''
                     }
                     required
                  />
               </PriceContainer>
               <PriceContainer>
                  <FormInput
                     name='mostPrice'
                     type='text'
                     handleChange={handleChange}
                     value={mostPrice}
                     label='حداکثر'
                     subLabel={
                        buyingPrice !== '۰'
                           ? `ک${dkpc.commission}س۰ ${getPersianCurrency(
                                getApproxProfit(0)
                             )}`
                           : ''
                     }
                     required
                  />
               </PriceContainer>
               <PriceContainer>
                  <FormInput
                     name='buyingPrice'
                     type='text'
                     handleChange={handleChange}
                     value={buyingPrice}
                     label='خرید'
                     subLabel={
                        `ت ${dkpc.sellingStock}` +
                        (buyingPrice !== '۰' ? getHajmString() : '')
                     }
                  />
               </PriceContainer>
               <PriceContainerNarrow>
                  <FormInput
                     name='packingPrice'
                     type='text'
                     handleChange={handleChange}
                     value={packingPrice}
                     label='ب‌ب'
                     subLabel={`ق‌ا ${getPersianCurrency(
                        dkpc.sellingPrice / 10
                     )}`}
                  />
               </PriceContainerNarrow>

               <AvgMonthSelling
                  onClick={() => navigator.clipboard.writeText(dkpc.dkp)}
               >
                  <span>م‌ف‌م</span>
                  <span>{Math.floor(dkpc.avgMonthSellingCount)}</span>
                  <span>زا‌ف</span>
                  <EstimatedSellingTime>
                     {Math.floor(
                        dkpc.sellingStock /
                           (dkpc.avgMonthSellingCount > 0
                              ? dkpc.avgMonthSellingCount
                              : 1000000)
                     )}
                     م
                  </EstimatedSellingTime>
               </AvgMonthSelling>
               <CustomButton isSmall disabled={!anyChange}>
                  ثبت
               </CustomButton>
            </DKPCFormContainer>
         </form>
         {currentUser.isKK && dkpc.brandSeller.brandOffLimit !== 100 && (
            <IsKKContainer>
               <CheckboxInput
                  label={'کک؟'}
                  checked={dkpc.isKKOn}
                  handleChange={() =>
                     dispatch(toggleSellerDKPCIsKKOnStart(dkpc.id, sellerId))
                  }
               />
               <div>
                  {dkpc.seller.isKKOn || dkpc.isKKOn || dkpc.brandSeller.isKKOn
                     ? 'بله'
                     : 'خیر'}
               </div>
            </IsKKContainer>
         )}
         <ActiveAndRemoveButtons>
            <RemoveButtonContainer onClick={() => setIsDelPopupOpen(true)}>
               &#10005;
            </RemoveButtonContainer>
            <CheckboxInput
               label={'فعال'}
               checked={dkpc.isActiveBot}
               handleChange={() =>
                  dispatch(toggleSellerDKPCIsActiveBotStart(dkpc.id, sellerId))
               }
            />
         </ActiveAndRemoveButtons>
         <AcknowledgeAction
            isPopUpOpen={isDelPopupOpen}
            setIsPopUpOpen={setIsDelPopupOpen}
            yesFunction={() => dispatch(delSellerDKPC(dkpc.id, sellerId))}
         />
         <CustomButton
            isSmall
            isGreen
            onClick={() => dispatch(checkSellerDKPC(dkpc.id, sellerId))}
         >
            چک
         </CustomButton>
         <CustomButton
            isSmall
            isRed
            onClick={() =>
               dispatch(checkSellerDKPCOrder(dkpc.dkpcNum, sellerId))
            }
         >
            بررسی
         </CustomButton>
         {
            //    dkpc.stateRecords.length > 1 && (
            //    <CustomButton
            //       isSmall
            //       inverted
            //       onClick={() => setIsPriceChartPopupOpen(true)}
            //    >
            //       نمودار
            //    </CustomButton>
            // )
         }

         {isPriceChartPopupOpen && (
            <Popup
               modal
               open={isPriceChartPopupOpen}
               onClose={() => setIsPriceChartPopupOpen(false)}
            >
               <PriceLineChart stateRecords={dkpc.stateRecords} />
            </Popup>
         )}
      </DKPCContainer>
   );
};

const validateProductInventorySpecs = (englishDKPCSpecs) => {
   let isValid = true;
   let errors = [];

   // console.log(englishDKPCSpecs);
   const numFromStrProductInventorySpecs = Object.fromEntries(
      Object.entries(englishDKPCSpecs).map(([k, v]) => [k, Number(v)])
   );
   const { mostPrice, leastPrice } = numFromStrProductInventorySpecs;

   if (leastPrice > mostPrice) {
      isValid = false;
      errors.push('قیمت حداکثر نمی‌تواند بیشتر از قیمت حداقل باشد.');
   }
   for (let e of errors) {
      console.log(e);
      toastWarning(e);
   }

   return isValid;
};

export default DKPCOrderSummary;
