import styled from 'styled-components';

export const VariantCompactContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   direction: rtl;
`;

export const ImageContainer = styled.div`
   width: 100px;
   height: 100px;
`;

export const Detail = styled.div``;
