import React, { useState } from 'react';

//Redux Hooks
import { useDispatch } from 'react-redux';

//Redux Actions
import { phoneLoginBackend } from '../../redux/user/user.actions';

//Components
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

//Utils
import { persianNumberToEnglish } from '../../utils/font.utils';

//Stules
import {
   PhoneInputContainer,
   PhoneInputTitle,
   ButtonsBarContainer,
} from './phone-password-input.styles';

const PhonePasswordInput = () => {
   const dispatch = useDispatch();
   const [userCredentials, setCredentials] = useState({
      phone: '',
      password: '',
   });

   const { phone, password } = userCredentials;

   const handleSubmit = async (event) => {
      event.preventDefault();
      dispatch(phoneLoginBackend(persianNumberToEnglish(phone), password));
   };

   const handleChange = (event) => {
      const { value, name } = event.target;

      setCredentials({
         ...userCredentials,
         [name]: value,
      });
   };

   return (
      <PhoneInputContainer>
         <PhoneInputTitle>پسوردتان را وارد کنید</PhoneInputTitle>
         <form onSubmit={handleSubmit}>
            <FormInput
               name='phone'
               type='phone'
               handleChange={handleChange}
               value={phone}
               label='موبایل'
               required
            />
            <FormInput
               name='password'
               type='password'
               handleChange={handleChange}
               value={password}
               label='پسورد'
               required
            />
            <ButtonsBarContainer>
               <CustomButton type='submit'> ورود </CustomButton>
            </ButtonsBarContainer>
         </form>
      </PhoneInputContainer>
   );
};

export default PhonePasswordInput;
