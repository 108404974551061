import styled from 'styled-components';

export const VariantProductsContainer = styled.div`
   display: flex;
   flex-direction: column;
   min-height: 400px;
   height: 95%;
   overflow: hidden;
`;

export const VariantProductsList = styled.div`
   display: flex;
   flex-direction: column;
`;

export const AddPQ = styled.div`
   direction: rtl;
   display: flex;
   flex-direction: column;
`;

export const FlexRow = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;

   img {
      margin-left: 10px;
      border-radius: 5px;
   }
`;
