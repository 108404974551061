import React, { useEffect, useState } from 'react';
import {
   getPersianCurrency,
   getPersianPositiveNumbers,
   persianNumberToEnglish,
} from '../../utils/font.utils';
import FormInput from '../form-input/form-input.component';
import {
   CalculatorContainer,
   FinalSellPrice,
   FormContainer,
} from './digi-calculator.styles';

const DigiCalculator = () => {
   const [sellPrice, setSellPrice] = useState(0);
   const [specs, setSpecs] = useState({
      buyPrice: '',
      commission: 8,
      profit: 10,
   });

   const { buyPrice, commission, profit } = specs;

   const handleChange = (event) => {
      const { value, name } = event.target;

      // console.log(name);

      setSpecs({
         ...specs,
         [name]: name.toLowerCase().includes('price')
            ? getPersianCurrency(value)
            : getPersianPositiveNumbers(value),
      });
   };

   useEffect(() => {
      const numBuyPrice = Number(
         persianNumberToEnglish(buyPrice.replaceAll(',', ''))
      );
      const engCommission = Number(persianNumberToEnglish(commission));
      const engProfit = Number(persianNumberToEnglish(profit));

      const cheapFormula = Math.ceil(
         ((1 + engProfit / 100) * numBuyPrice + (70000 / 10) * 1.09) /
            (1 - (engCommission / 100) * 1.09)
      );
      const expensiveFormula = Math.ceil(
         ((1 + engProfit / 100) * numBuyPrice + (0 / 10) * 1.09) /
            (1 - ((engCommission + 7) / 100) * 1.09)
      );

      // console.log(cheapFormula, expensiveFormula);

      const finalSellPrice =
         cheapFormula < 100000 ? cheapFormula : expensiveFormula;
      setSellPrice(Math.ceil(finalSellPrice / 100) * 100);
   }, [specs]);

   return (
      <CalculatorContainer>
         <FormContainer>
            <FormInput
               name='buyPrice'
               type='text'
               handleChange={handleChange}
               value={buyPrice}
               label='قیمت خرید'
               required
            />
            <FormInput
               name='commission'
               type='text'
               handleChange={handleChange}
               value={commission}
               label='کمیسیون'
               required
            />
            <FormInput
               name='profit'
               type='text'
               handleChange={handleChange}
               value={profit}
               label='درصد سود'
               required
            />
         </FormContainer>
         <FinalSellPrice>
            {buyPrice.length > 3 ? getPersianCurrency(sellPrice) : '؟'}
         </FinalSellPrice>
      </CalculatorContainer>
   );
};

export default DigiCalculator;
