import InventoryActionTypes from '../inventory/inventory.types';
import ShopActionTypes from './shop.types';

const INITIAL_STATE = {
   info: null,
   variants: null,
   error: null,
};

const shopReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case ShopActionTypes.GET_SHOP_SUCCESS:
         return {
            ...state,
            info: action.payload.info,
            variants: action.payload.variants,
            error: null,
         };
      case ShopActionTypes.ADD_CHANGE_SHOP_VARIANT_START:
         return {
            ...state,
            variants: [
               ...state.variants.filter((v) => v.id !== action.payload.id),
               action.payload,
            ],
            error: null,
         };
      case ShopActionTypes.DEL_SHOP_VARIANT:
         return {
            ...state,
            variants: [
               ...state.variants.filter((v) => v.id !== action.payload.id),
            ],
            error: null,
         };

      case InventoryActionTypes.ADD_CHANGE_PRODUCT_QUANTITY_TO_VARIANT_SUCCESS:
      case InventoryActionTypes.REMOVE_PRODUCT_QUANTITY_FROM_VARIANT_SUCCESS:
      case InventoryActionTypes.CHANGE_PRODUCT_QUANTITY_OF_VARIANT_SUCCESS:
         return {
            ...state,
            variants: [
               ...state.variants.filter((v) => v.id !== action.payload.id),
               action.payload,
            ],
         };

      case ShopActionTypes.GET_PRODUCTS_FAILURE:
         return {
            ...state,
            variants: action.payload,
         };

      default:
         return state;
   }
};

export default shopReducer;
