import React from 'react';

//Styles
import {
   GroupContainer,
   FormInputContainer,
   FormInputLabel,
   RequiredStar,
   ValidateTik,
   ValidateCross,
   SubLabel,
} from './form-input.styles';

const FormInput = ({ handleChange, label, subLabel, ...props }) => {
   return (
      <GroupContainer>
         <FormInputContainer onChange={handleChange} {...props} />
         {label ? (
            <FormInputLabel className={props.value ? 'shrink' : ''}>
               {label}
               {props.required && <RequiredStar> *</RequiredStar>}
               {props.validate && props.value ? (
                  props.validate() ? (
                     <ValidateTik> ✓</ValidateTik>
                  ) : (
                     <ValidateCross> ✕</ValidateCross>
                  )
               ) : (
                  ''
               )}
            </FormInputLabel>
         ) : null}
         {subLabel ? <SubLabel>{subLabel}</SubLabel> : ''}
      </GroupContainer>
   );
};

export default FormInput;
