import UserActionTypes from './user.types';

export const signInSuccess = (data) => ({
   type: UserActionTypes.SIGN_IN_SUCCESS,
   payload: { data },
});

export const signInFailure = (error) => ({
   type: UserActionTypes.SIGN_IN_FAILURE,
   payload: error,
});

export const phoneLoginBackend = (phone, password) => ({
   type: UserActionTypes.PHONE_LOGIN_BACKEND,
   payload: { phone, password },
});

export const checkUserSession = () => ({
   type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOut = () => ({
   type: UserActionTypes.SIGN_OUT,
});
