import styled from 'styled-components';

export const DKPCInputContainer = styled.div`
   width: 100%;
   direction: rtl;
   padding: 30px;

   max-height: 90vh;
   overflow: auto;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      padding: 10px;
   }
`;

export const ButtonsBarContainer = styled.div`
   margin-top: 25px;
   display: flex;
   justify-content: space-between;
`;

export const FormContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;

   .one {
      width: 200px;
   }
   .two {
      width: 500px;
   }
`;

export const SectionTitle = styled.div`
   padding: 20px 0px;
   font-size: ${(props) => props.theme.fontSizes.medium};
`;

export const FormGroupContainer = styled.div`
   display: flex;
   flex-direction: row;

   gap: 30px;
   justify-content: space-between;
`;
