import React, { useState } from 'react';
import CustomButton from '../custom-button/custom-button.component';

//Styles
import {
   GroupContainer,
   FileInputContainer,
   RequiredStar,
   FileInputName,
   InputContainer,
   Label,
} from './file-input.styles';

const FileInput = ({ handleChange, label, ...props }) => {
   const [fileName, setFileName] = useState('');

   const hiddenFileInput = React.useRef(null);
   const handleClick = (event) => {
      hiddenFileInput.current.click();
   };

   const handleInsideChange = (e) => {
      setFileName(e.target.files[0].name);
   };

   return (
      <GroupContainer>
         {label && <Label>{label}</Label>}
         <InputContainer>
            <CustomButton onClick={handleClick} isBlue type='button'>
               ارسال فایل <RequiredStar> * </RequiredStar>
            </CustomButton>
            <FileInputName htmlFor='files'>{fileName}</FileInputName>
            <FileInputContainer
               ref={hiddenFileInput}
               id='files'
               type='file'
               onChange={(e) => {
                  handleInsideChange(e);
                  handleChange(e);
               }}
               {...props}
            />
         </InputContainer>
      </GroupContainer>
   );
};

export default FileInput;
