import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

	// Box Sizing and Reset Margins
	*,*::before,*::after {
		box-sizing: border-box;
		margin:0;
		padding:0;
	}	

	// Set up the body
	body {
		line-height: 1.5;
		display: flex;
		flex-direction: column;
		font-family: "IRANSans";
		background: ${(props) => props.theme.colors.primary};
    	color: ${(props) => props.theme.colors.dark};
		min-height:100vh;


		.popup-overlay {
			background: rgba(0, 0, 0, 0.31);
			border-radius: 16px;
			box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
			backdrop-filter: blur(5px);
			-webkit-backdrop-filter: blur(5px);
			border: 1px solid rgba(255, 255, 255, 0.3);
		}
		.popup-content {

			background: ${(props) => props.theme.colors.primary};
			min-width: 50vw;

			@media screen and (max-width: ${(props) => props.theme.breakPoints.lg}) {
				min-width: 85vw;
			}

			@media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
				min-width: 95vw;
			}

		}
	}

	//Set up font size
	html {
    font-size: 12px;
  }

	// make images easier to work with
	img, picture{
		max-width: 100%;
		display: block;
	}

	// make form elements easier to work with
	input,
	button,
	textarea,
	select{
		font: inherit;
	}

	// make a elements easier to work with
	a {
		text-decoration: none;
		color: black;
	}

	h1, h2, h3 {
		font: inherit;
	}

	


	:root {
  --toastify-color-light: ${(props) => props.theme.colors.primary};
  --toastify-color-dark: ${(props) => props.theme.colors.dark};
  --toastify-color-info: #3498db;
  --toastify-color-success: ${(props) => props.theme.colors.success};
  --toastify-color-warning: ${(props) => props.theme.colors.danger};
  --toastify-color-error: ${(props) => props.theme.colors.error};
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 104px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: IRANSansLight;
  --toastify-z-index: 9999;

  --toastify-text-color-light: ${(props) => props.theme.colors.dark};
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.whatsapp_widget_wrapper {

	font-family: "IRANSans", BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	direction: rtl;
	align-items: flex-start !important;
	margin: 10px;

	@media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
		margin: 0px;
			}
	
}

.whatsapp_widget_icon{
	opacity:0.6;

	:hover{
		opacity:1;
	}
}




`;

// .whatsapp_widget_header_icon {
// 	background-color: unset !important;
//     width: 18px;
// 	 img{
// 		 display:none;
// 	 }
// }
