import React, { useEffect, useState } from 'react';

//Router Hooks
import { useNavigate, useSearchParams } from 'react-router-dom';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Styles
import {
   selectIsProductsLoaded,
   selectProducts,
} from '../../redux/inventory/inventory.selectors';
import {
   getExcelStart,
   getInventorypsStart,
   getInventoryOrdersStart,
   getProductsStart,
   transformExcel,
} from '../../redux/inventory/inventory.actions';
import {
   InventoryPageContainer,
   InventorypList,
   AddpButton,
   InventorySideBar,
   InventoryTitle,
   Filters,
   BrandsListButton,
   ExcelButton,
   ProductsList,
   AddChangeProductButton,
   TransformFileContainer,
} from './inventory.styles';
import Popup from 'reactjs-popup';
import SearchBar from '../../components/search-bar/search-bar.components';
import { persianNumberToEnglish } from '../../utils/font.utils';
import CheckboxInput from '../../components/checkbox-input/checkbox-input.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import FileInput from '../../components/file-input/file-input.component';
import { getPercent } from '../../utils/math.utils';
import BatchSortRadiobox from '../../components/batch-sort-radiobox/batch-sort-radiobox.component';
import ProductSummary from '../../components/product-summary/product-summary.component';
import AddChangeProduct from '../../components/add-change-product/add-change-product.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import Spinner from '../../components/spinner/spinner.component';

function turn0ToInfinity(x) {
   return x === 0 ? Infinity : x;
}
const allPosibilities = [
   {
      sort_id: 0,
      name: 'جدیدترین',
      func: (p1, p2) => (p1, p2) => p2.id - p1.id,
   },
   {
      sort_id: 1,
      name: 'بیشترین حجم موجودی',
      func: (p1, p2) => (p1, p2) =>
         p2.countInStock * p2.buyingPrice - p1.countInStock * p1.buyingPrice,
   },
   {
      sort_id: 2,
      name: 'بیشترین تعداد موجودی',
      func: (p1, p2) => (p1, p2) => p2.warehouseStock - p1.warehouseStock,
   },
   {
      sort_id: 3,
      name: 'مرتبط‌ترین',
      func: (p1, p2) => (p1, p2) => p2.relevance - p1.relevance,
   },
   {
      sort_id: 4,
      name: 'انبارگردانی زیاد',
      func: (p1, p2) => (p1, p2) =>
         Math.abs(p2.anbargardany - p2.warehouseStock) -
         Math.abs(p1.anbargardany - p1.warehouseStock),
   },
];

export const InventoryPage = () => {
   const dispatch = useDispatch();
   const isLoaded = useSelector(selectIsProductsLoaded);
   const products = useSelector(selectProducts);
   const currentUser = useSelector(selectCurrentUser);
   const navigate = useNavigate();

   let [searchParams, setSearchParams] = useSearchParams();

   useEffect(() => {
      // console.log('currentUser', currentUser, !currentUser);
      if (!currentUser) {
         navigate('/');
      } else {
         dispatch(getProductsStart());
      }
   }, []);

   const [query, setQuery] = useState('');
   const [noWarehousStock, setNoWarehouseStock] = useState(false);
   const [noAnbargardany, setNoAnbargardany] = useState(false);

   const handleSearchChange = (event) => {
      const { value } = event.target;
      setQuery(persianNumberToEnglish(value));
   };

   const [sortFunction, setSortFunction] = useState(
      (obj1, obj2) => (obj1, obj2) => obj1.id - obj2.id
   );

   const handleSort = (sortfunc) => {
      setSortFunction(sortfunc);
   };

   const myStrip = (str) =>
      str.replaceAll('‌', '').replaceAll('ي', 'ی').replaceAll(' ', '');

   useEffect(() => {
      if (isLoaded && query.length > 2) {
         setSortFunction(allPosibilities[3].func);
         // console.log('query', query);
         products.forEach((p) => {
            p.relevance = 0;

            query
               .toLowerCase()
               .split(' ')
               .forEach((s, idx) => {
                  if (myStrip(p.name.toLowerCase()).includes(myStrip(s))) {
                     p.relevance += 10 - idx;
                  }
               });
         });
      }
   }, [query, products, isLoaded]);

   const getFilteredProducts = () => {
      let filtered = products
         .slice()
         .sort((p1, p2) => sortFunction(p1, p2))
         .filter((p) => (noWarehousStock ? p.warehouseStock === 0 : true))
         .filter((p) => (noAnbargardany ? p.anbargardany === 0 : true))
         .filter((p) => (query.length > 2 ? p.relevance !== 0 : true));

      // if (query.length > 2) {
      //    filtered = filtered.filter((p) => {
      //       return myStrip(p.name.toLowerCase()).includes(myStrip(query));
      //    });
      // }

      const page = searchParams.get('page') ? searchParams.get('page') : 1;
      return filtered.slice((page - 1) * 150, 150 + (page - 1) * 150);
   };

   const [fileToBeTransformed, setFileToBeTransformed] = useState();

   // console.log('sortFunction', sortFunction);
   return (
      currentUser && (
         <div>
            <TransformFileContainer>
               <FileInput
                  name='fileToBeTransformed'
                  handleChange={(e) =>
                     setFileToBeTransformed(e.target.files[0])
                  }
                  accept='.html, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  required
               />
               <CustomButton
                  disabled={!fileToBeTransformed}
                  onClick={() => dispatch(transformExcel(fileToBeTransformed))}
               >
                  تبدیل کن!
               </CustomButton>
            </TransformFileContainer>
            {isLoaded && products.length > 0 ? (
               <InventoryPageContainer>
                  <InventorySideBar>
                     <InventoryTitle>انبار</InventoryTitle>
                     <SearchBar
                        placeHolder='جستجو'
                        handleChange={handleSearchChange}
                     ></SearchBar>
                     <Filters>
                        <CheckboxInput
                           label={'بدون موجودی انبار'}
                           checked={noWarehousStock}
                           handleChange={() =>
                              setNoWarehouseStock(!noWarehousStock)
                           }
                        />
                        <CheckboxInput
                           label={'بدون انبارگردانی'}
                           checked={noAnbargardany}
                           handleChange={() =>
                              setNoAnbargardany(!noAnbargardany)
                           }
                        />
                     </Filters>
                     {
                        <AddChangeProduct />
                        // <ExcelButton
                        //    isGreen
                        //    onClick={() => dispatch(getExcelStart(params.inventoryId))}
                        // >
                        //    دریافت اکسل
                        // </ExcelButton>
                     }

                     <div>تعداد: {getFilteredProducts().length}</div>

                     <a
                        href='/inventory/excel/'
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        <CustomButton>خروجی سپیدار کالاها</CustomButton>
                     </a>
                     {
                        //    inventorys && (
                        //    <InventoryStats
                        //       inventory={inventorys.find(
                        //          (inventory) =>
                        //             inventory.id === Number(params.inventoryId)
                        //       )}
                        //       ps={ps}
                        //    />
                        // )
                     }
                  </InventorySideBar>
                  <ProductsList>
                     <BatchSortRadiobox
                        allPosibilities={allPosibilities}
                        handleSort={(sortFunction) => handleSort(sortFunction)}
                     />
                     {getFilteredProducts().map((p) => (
                        <ProductSummary product={p} key={p.id} />
                     ))}
                  </ProductsList>
               </InventoryPageContainer>
            ) : isLoaded && products.length === 0 ? (
               <div>
                  <InventoryTitle>انبار</InventoryTitle>
                  <AddChangeProduct />
               </div>
            ) : (
               <Spinner />
            )}
         </div>
      )
   );
};

export default InventoryPage;
