import React, { useEffect, useState } from 'react';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Components
import CustomButton from '../custom-button/custom-button.component';
import FormInput from '../form-input/form-input.component';

//Utils
import {
   getPersianPositiveNumbers,
   getPersianCurrency,
   persianNumberToEnglish,
} from '../../utils/font.utils';
import { toastWarning } from '../../utils/toast.utils';

//Styles
import {
   ProductContainer,
   ImageContainer,
   NameContainer,
   PriceContainer,
   PriceContainerWide,
   ProductFormContainer,
   RemoveButtonContainer,
   DigiLink,
   IsKKContainer,
   StockContainer,
   AvgMonthSelling,
   EstimatedSellingTime,
   ActiveAndRemoveButtons,
   PriceContainerNarrow,
   ListContainer,
} from './product-summary.styles';
import {
   checkSellerProduct,
   delSellerProduct,
   setSellerProductStart,
   toggleSellerProductIsActiveBotStart,
   toggleSellerProductIsKKOnStart,
} from '../../redux/seller/seller.actions';
import AcknowledgeAction from '../acknowledge-action/acknowledge-action.component';
import CheckboxInput from '../checkbox-input/checkbox-input.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import Popup from 'reactjs-popup';
import PriceLineChart from '../price-line-chart/price-line-chart.component';
import DKPCCompact from '../dkpc-compact/dkpc-compact.component';
import AddChangeProduct from '../add-change-product/add-change-product.component';
import VariantCompact from '../variant-compact/variant-compact.component';
import DKPCOrderSummary from '../dkpc-order-summary/dkpc-order-summary.component';
import { addChangeProductStart } from '../../redux/inventory/inventory.actions';

const ProductSummary = ({ product, sellerId }) => {
   const dispatch = useDispatch();

   const currentUser = useSelector(selectCurrentUser);

   const [isDelPopupOpen, setIsDelPopupOpen] = useState(false);

   const [isChangeProductPopupOpen, setIsChangeProductPopupOpen] =
      useState(false);

   const [isDKPCsPopupOpen, setIsDKPCsPopupOpen] = useState(false);

   const [anyChange, setAnyChange] = useState(false);
   const [productSpecs, setProductSpecs] = useState({
      buyingPrice: '',
      anbargardany: '',
   });

   const { buyingPrice, anbargardany } = productSpecs;

   useEffect(() => {
      setProductSpecs({
         ...productSpecs,
         buyingPrice: getPersianCurrency(product.buyingPrice),
         anbargardany: getPersianPositiveNumbers(product.anbargardany),
      });
   }, []);

   const handleSubmit = async (event) => {
      event.preventDefault();
      // console.log(productSpecs);
      const englishProductSpecs = Object.fromEntries(
         Object.entries(productSpecs).map(([k, v]) => [
            k,
            persianNumberToEnglish(v.replaceAll(',', '')),
         ])
      );
      // console.log(englishProductSpecs);
      const isValid = validateProductInventorySpecs(englishProductSpecs);

      if (isValid === true) {
         setAnyChange(false);
         dispatch(
            addChangeProductStart({
               productId: product.id,
               name: product.name,
               imageLink: product.imageLink,
               ...englishProductSpecs,
            })
         );
      }
   };

   const handleChange = (event) => {
      const { value, name } = event.target;
      setAnyChange(true);

      setProductSpecs({
         ...productSpecs,

         [name]: name.toLowerCase().includes('price')
            ? getPersianCurrency(value)
            : getPersianPositiveNumbers(value),
      });
   };

   const getHajmString = () => {
      const engBuyingPrice = persianNumberToEnglish(
         buyingPrice.replaceAll(',', '')
      );
      const hajm = product.countInStock * engBuyingPrice;

      return hajm > 1000000
         ? ` ح ${getPersianCurrency(Math.floor(hajm / 100000) / 10)}م`
         : ` ح ${getPersianCurrency(Math.floor(hajm / 1000))}هزار`;
   };

   return (
      <ProductContainer>
         <div>{Math.abs(product.anbargardany - product.warehouseStock)}</div>
         <ImageContainer onClick={() => setIsChangeProductPopupOpen(true)}>
            <img src={product.imageLink} alt='item' />
         </ImageContainer>
         <NameContainer
            href={`https://www.digikala.com/search/?q=` + product.name}
            target='_blank'
            rel='noopener noreferrer'
         >
            {product.name}
         </NameContainer>
         <StockContainer>انبار {product.warehouseStock}</StockContainer>
         <StockContainer>دیجی {product.digikalaStock}</StockContainer>

         {
            <form onSubmit={handleSubmit}>
               <ProductFormContainer>
                  <PriceContainer>
                     <FormInput
                        name='buyingPrice'
                        type='text'
                        handleChange={handleChange}
                        value={buyingPrice}
                        label='خرید'
                        subLabel={
                           `ت ${product.countInStock}` +
                           (buyingPrice !== '۰' ? getHajmString() : '')
                        }
                     />
                  </PriceContainer>
                  <PriceContainer>
                     <FormInput
                        name='anbargardany'
                        type='text'
                        handleChange={handleChange}
                        value={anbargardany}
                        label='انبارگردانی'
                     />
                  </PriceContainer>
                  <CustomButton isSmall disabled={!anyChange}>
                     ثبت
                  </CustomButton>
               </ProductFormContainer>
            </form>
         }
         {
            // <ActiveAndRemoveButtons>
            //    <RemoveButtonContainer onClick={() => setIsDelPopupOpen(true)}>
            //       &#10005;
            //    </RemoveButtonContainer>
            // </ActiveAndRemoveButtons>
         }
         {
            // <AcknowledgeAction
            //    isPopUpOpen={isDelPopupOpen}
            //    setIsPopUpOpen={setIsDelPopupOpen}
            //    yesFunction={() => dispatch(delSellerProduct(product.id, sellerId))}
            // />
         }
         {
            // <CustomButton
            //    isSmall
            //    isGreen
            //    onClick={() =>
            //       dispatch(checkDKPCsWithThisProduct(product.id, sellerId))
            //    }
            // >
            //    چک
            // </CustomButton>
         }
         {
            <CustomButton
               isBlue
               disabled={
                  !(
                     (product.dkpcs && product.dkpcs.length > 0) ||
                     (product.variants && product.variants.length > 0)
                  )
               }
               onClick={() => setIsDKPCsPopupOpen(true)}
            >
               تنوع ها
            </CustomButton>
         }
         {isDKPCsPopupOpen && (
            <Popup
               modal
               open={isDKPCsPopupOpen}
               onClose={() => setIsDKPCsPopupOpen(false)}
            >
               <ListContainer>
                  {product.dkpcs && product.dkpcs.length > 0 && (
                     <div>
                        <div className='title'>دیجی‌کالا</div>
                        {product.dkpcs.map((dkpc) => (
                           <DKPCCompact dkpc={dkpc} key={dkpc.id} />
                        ))}
                     </div>
                  )}
                  {product.variants && product.variants.length > 0 && (
                     <div>
                        <div className='title second'>مالتو</div>
                        {product.variants.map((v) => (
                           <VariantCompact variant={v} key={v.id} />
                        ))}
                     </div>
                  )}
               </ListContainer>
            </Popup>
         )}

         {isChangeProductPopupOpen && (
            <AddChangeProduct
               productToChange={product}
               setIsPopUpOpen={() => setIsChangeProductPopupOpen(false)}
            />
         )}

         {
            //    isDKPCsListPopupOpen && (
            //    <Popup
            //       modal
            //       open={isDKPCsListPopupOpen}
            //       onClose={() => setIsDKPCsListPopupOpen(false)}
            //    >
            //       {product.allDKPCs.map((dkpc) => (
            //          <div>dkpc.name</div>
            //       ))}
            //    </Popup>
            // )
         }
      </ProductContainer>
   );
};

const validateProductInventorySpecs = (englishProductSpecs) => {
   let isValid = true;
   let errors = [];

   // console.log(englishProductSpecs);
   const numFromStrProductInventorySpecs = Object.fromEntries(
      Object.entries(englishProductSpecs).map(([k, v]) => [k, Number(v)])
   );
   const { mostPrice, leastPrice } = numFromStrProductInventorySpecs;

   if (leastPrice > mostPrice) {
      isValid = false;
      errors.push('قیمت حداکثر نمی‌تواند بیشتر از قیمت حداقل باشد.');
   }
   for (let e of errors) {
      console.log(e);
      toastWarning(e);
   }

   return isValid;
};

export default ProductSummary;
