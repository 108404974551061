import styled from 'styled-components';

export const BurgerIconContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 42px;

   cursor: pointer;
`;
export const BurgerIconLine1 = styled.span`
   border-radius: ${(props) => `${props.scale * 10}px`};
   height: ${(props) => `${props.scale * 7}px`};
   margin: ${(props) => `${props.scale * 7}px 0`};
   transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
   background-color: #e4ded2;

   width: 50%;

   transform-origin: bottom;
   transform: ${(props) =>
      props.isMenuOpen
         ? `rotatez(45deg) translate(${props.scale * 8}px, 0px)`
         : ''};
`;

export const BurgerIconLine2 = styled.span`
   border-radius: ${(props) => `${props.scale * 10}px`};
   height: ${(props) => `${props.scale * 7}px`};
   margin: ${(props) => `${props.scale * 7}px 0`};
   transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
   background-color: #e4ded2;

   width: 100%;

   transform-origin: top;
   transform: ${(props) => (props.isMenuOpen ? `rotatez(-45deg)` : '')};
`;

export const BurgerIconLine3 = styled.span`
   border-radius: ${(props) => `${props.scale * 10}px`};
   height: ${(props) => `${props.scale * 7}px`};
   margin: ${(props) => `${props.scale * 7}px 0`};
   transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
   background-color: #e4ded2;

   width: ${(props) => (props.isMenuOpen ? '50%' : '75%')};

   transform-origin: bottom;
   transform: ${(props) =>
      props.isMenuOpen
         ? `translate(${props.scale * 30}px, ${
              props.scale * -11
           }px) rotatez(45deg)`
         : ''};
`;

// input.burger-icon-input[type='checkbox']:checked ~ span:nth-of-type(1) {

// }

// input.burger-icon-input[type='checkbox']:checked ~ span:nth-of-type(2) {
//    transform-origin: top;
//    transform: rotatez(-45deg);
// }

// input.burger-icon-input[type='checkbox']:checked ~ span:nth-of-type(3) {
//    transform-origin: bottom;
//    width: 50%;
//    transform: translate(
//          calc(30px * var(--custom-scale)),
//          calc(-11px * var(--custom-scale))
//       )
//       rotatez(45deg);
// }
