import { createSelector } from 'reselect';

const selectSeller = (state) => state.seller;

export const selectSellerDKPCs = createSelector(
   [selectSeller],
   (seller) => seller.dkpcs
);

export const selectIsSellerDKPCsLoaded = createSelector(
   [selectSeller],
   (seller) => !!seller.dkpcs
);

export const selectSellerOrders = createSelector(
   [selectSeller],
   (seller) => seller.orders
);

export const selectIsSellerOrdersLoaded = createSelector(
   [selectSeller],
   (seller) => !!seller.orders
);
