import React from 'react';
import {
   VariantCompactContainer,
   Detail,
   ImageContainer,
} from './variant-compact.styles';

const VariantCompact = ({ variant }) => {
   console.log('variant', variant);
   const {
      name,
      mainLink,
      imageLink,
      productId,
      productVariantId,
      countInStock,
   } = variant;

   return (
      <VariantCompactContainer>
         <ImageContainer>
            <img src={imageLink} alt={name} />
         </ImageContainer>
         <a href={mainLink} target='_blank' rel='noreferrer'>
            {name}
         </a>
         <Detail>تعداد:{countInStock}</Detail>
         <Detail>{productId}</Detail>
         <Detail>{productVariantId}</Detail>
      </VariantCompactContainer>
   );
};

export default VariantCompact;
