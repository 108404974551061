import React from 'react';
import {
   DKPCCompactContainer,
   Detail,
   ImageContainer,
   NameContainer,
   StockContainer,
} from './dkpc-compact.styles';

const DKPCCompact = ({ dkpc }) => {
   console.log('dkpc', dkpc);
   const {
      dkp,
      dkpcNum,
      name,
      imageLink,
      sellerWarehouseStock,
      digikalaWarehouseStock,
      digikalaReservedStock,
   } = dkpc;

   return (
      <DKPCCompactContainer>
         <ImageContainer
            href={`https://seller.digikala.com/pwa/variant-management?filterSearchIn=search_term&filterSearchQuery=${dkpc.dkpcNum}`}
            target='_blank'
            rel='noopener noreferrer'
         >
            <img src={imageLink} alt={name} />
         </ImageContainer>

         <NameContainer
            href={'https://digikala.com/product/dkp-' + dkp}
            target='_blank'
            rel='noreferrer'
         >
            {name}
         </NameContainer>
         <StockContainer>
            <div>م د: {digikalaWarehouseStock}</div>
            <div>ر د: {digikalaReservedStock}</div>
            <div>م: {sellerWarehouseStock}</div>
         </StockContainer>
         <Detail>{dkp}</Detail>
         <Detail>{dkpcNum}</Detail>
      </DKPCCompactContainer>
   );
};

export default DKPCCompact;
