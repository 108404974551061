import UserActionTypes from './user.types';

const INITIAL_STATE = {
   currentUser: null,
   phone: null,
   error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case UserActionTypes.PHONE_REGISTER_OR_LOGIN_BACKEND:
         return {
            ...state,
            phone: action.payload,
            error: null,
         };
      case UserActionTypes.SIGN_IN_SUCCESS:
         return {
            ...state,
            currentUser: action.payload.data.user,
            phone: action.payload.phone,
            error: null,
         };

      case UserActionTypes.SIGN_OUT:
         return {
            ...state,
            currentUser: null,
            phone: null,
            error: null,
         };
      case UserActionTypes.SIGN_IN_FAILURE:
         return {
            ...state,
            error: action.payload,
         };

      default:
         return state;
   }
};

export default userReducer;
