//Libraries
import starkString from 'starkstring';

export const strPurifier = (str) => str.replace('‌', '').replace('آ', 'ا');

export const getPersianCurrency = (str) => {
   str = persianNumberToEnglish(str); // changing all numbers to English
   str = str.replace(/[^\d.-]/g, ''); // delete all characters except numbers and '.'
   return starkString(str).toCurrency().persianNumber().toString(); // changing back to Persian
};

export const getPersianPositiveNumbers = (str) => {
   str = persianNumberToEnglish(str); // changing all numbers to English
   str = str.replace(/[^\d.-]/g, ''); // delete all characters except numbers and '.'
   str = str.replace('-', ''); // delete '-' for not allowing negative numbers
   return englishNumberToPersian(str); // changing back to Persian
};

export const getPersianDigitisToWords = (str) => {
   return starkString(str).digitsToWords().toString();
};

export const englishNumberToPersian = (englishNumber) => {
   return String(englishNumber)
      .replaceAll('0', '۰')
      .replaceAll('1', '۱')
      .replaceAll('2', '۲')
      .replaceAll('3', '۳')
      .replaceAll('4', '۴')
      .replaceAll('5', '۵')
      .replaceAll('6', '۶')
      .replaceAll('7', '۷')
      .replaceAll('8', '۸')
      .replaceAll('9', '۹');
};

export const persianNumberToEnglish = (persianNumber) => {
   return String(persianNumber)
      .replaceAll('۰', '0')
      .replaceAll('۱', '1')
      .replaceAll('۲', '2')
      .replaceAll('۳', '3')
      .replaceAll('۴', '4')
      .replaceAll('۵', '5')
      .replaceAll('۶', '6')
      .replaceAll('۷', '7')
      .replaceAll('۸', '8')
      .replaceAll('۹', '9');
};
