import { createSelector } from 'reselect';

const selectShop = (state) => state.shop;

export const selectShopInfo = createSelector([selectShop], (shop) => shop.info);

export const selectShopVariants = createSelector(
   [selectShop],
   (shop) => shop.variants
);

export const selectIsShopVariantsLoaded = createSelector(
   [selectShop],
   (shop) => !!shop.variants
);
