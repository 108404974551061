import React from 'react';

import errorGif from '../../assets/1140-error-outline.gif';

//Stles
import { ErrorImageOverlay, ErrorImageText } from './error-boundary.styles';

class ErrorBoundary extends React.Component {
   constructor() {
      super();

      this.state = {
         hasErrored: false,
      };
   }

   static getDerivedStateFromError(error) {
      // process the error
      return { hasErrored: true };
   }

   componentDidCatch(error, info) {
      console.log(error);
      console.log('Error-Boundry');
   }

   render() {
      if (this.state.hasErrored) {
         return (
            <ErrorImageOverlay>
               <img src={errorGif} alt='wait until the page loads' />
               <ErrorImageText>
                  مشکلی به وجود آمده است. به صفحه‌ی اصلی بروید.
               </ErrorImageText>
            </ErrorImageOverlay>
         );
      }

      return this.props.children;
   }
}

export default ErrorBoundary;
