import React, { useEffect, useState } from 'react';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Components
import CustomButton from '../custom-button/custom-button.component';
import FormInput from '../form-input/form-input.component';

//Utils
import {
   getPersianPositiveNumbers,
   getPersianCurrency,
   persianNumberToEnglish,
} from '../../utils/font.utils';
import { toastWarning } from '../../utils/toast.utils';

//Styles
import {
   VariantContainer,
   ImageContainer,
   NameContainer,
   PriceContainer,
   PriceContainerWide,
   VariantFormContainer,
   RemoveButtonContainer,
   DigiLink,
   IsKKContainer,
   StockContainer,
   AvgMonthSelling,
   EstimatedSellingTime,
   ActiveAndRemoveButtons,
   PriceContainerNarrow,
} from './variant-summary.styles';
import {
   checkSellerVariant,
   delSellerVariant,
   setSellerVariantStart,
   toggleSellerVariantIsActiveBotStart,
   toggleSellerVariantIsKKOnStart,
} from '../../redux/seller/seller.actions';
import AcknowledgeAction from '../acknowledge-action/acknowledge-action.component';
import CheckboxInput from '../checkbox-input/checkbox-input.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import Popup from 'reactjs-popup';
import PriceLineChart from '../price-line-chart/price-line-chart.component';
import DKPCCompact from '../dkpc-compact/dkpc-compact.component';
import AddChangeVariant from '../add-change-variant/add-change-variant.component';
import VariantProducts from '../variant-products/variant-products.component';

const VariantSummary = ({ variant, shopId }) => {
   const dispatch = useDispatch();

   const currentUser = useSelector(selectCurrentUser);

   const [isDelPopupOpen, setIsDelPopupOpen] = useState(false);

   const [isChangeVariantPopupOpen, setIsChangeVariantPopupOpen] =
      useState(false);

   const [isProductsPopupOpen, setIsProductsPopupOpen] = useState(false);

   const [anyChange, setAnyChange] = useState(false);
   const [variantSpecs, setVariantSpecs] = useState({
      buyingPrice: '',
   });

   const { buyingPrice } = variantSpecs;

   useEffect(() => {
      setVariantSpecs({
         ...variantSpecs,
         buyingPrice: getPersianCurrency(variant.buyingPrice),
      });
   }, []);

   const handleSubmit = async (event) => {
      event.preventDefault();
      // console.log(variantSpecs);
      const englishVariantSpecs = Object.fromEntries(
         Object.entries(variantSpecs).map(([k, v]) => [
            k,
            persianNumberToEnglish(v.replaceAll(',', '')),
         ])
      );
      // console.log(englishVariantSpecs);
      const isValid = validateVariantInventorySpecs(englishVariantSpecs);

      if (isValid === true) {
         setAnyChange(false);
         // dispatch(
         //    setVariantStart({
         //       variantId: variant.id,
         //       ...englishVariantSpecs,
         //    })
         // );
      }
   };

   const handleChange = (event) => {
      const { value, name } = event.target;
      setAnyChange(true);

      setVariantSpecs({
         ...variantSpecs,

         [name]: name.toLowerCase().includes('price')
            ? getPersianCurrency(value)
            : getPersianPositiveNumbers(value),
      });
   };

   return (
      <VariantContainer>
         <ImageContainer onClick={() => setIsChangeVariantPopupOpen(true)}>
            <img src={variant.imageLink} alt='item' />
         </ImageContainer>
         <NameContainer
            href={variant.mainLink}
            target='_blank'
            rel='noopener noreferrer'
         >
            {variant.name}
         </NameContainer>
         {
            // <form onSubmit={handleSubmit}>
            //    <VariantFormContainer>
            //       <PriceContainer>
            //          <FormInput
            //             name='buyingPrice'
            //             type='text'
            //             handleChange={handleChange}
            //             value={buyingPrice}
            //             label='خرید'
            //             subLabel={
            //                `ت ${variant.countInStock}` +
            //                (buyingPrice !== '۰' ? getHajmString() : '')
            //             }
            //          />
            //       </PriceContainer>
            //       <CustomButton isSmall disabled={!anyChange}>
            //          ثبت
            //       </CustomButton>
            //    </VariantFormContainer>
            // </form>
         }

         {
            //    <ActiveAndRemoveButtons>
            //    <RemoveButtonContainer onClick={() => setIsDelPopupOpen(true)}>
            //       &#10005;
            //    </RemoveButtonContainer>
            // </ActiveAndRemoveButtons>
            // <AcknowledgeAction
            //    isPopUpOpen={isDelPopupOpen}
            //    setIsPopUpOpen={setIsDelPopupOpen}
            //    yesFunction={() => dispatch(delSellerVariant(variant.id, sellerId))}
            // />
         }
         {
            // <CustomButton
            //    isSmall
            //    isGreen
            //    onClick={() =>
            //       dispatch(checkDKPCsWithThisVariant(variant.id, sellerId))
            //    }
            // >
            //    چک
            // </CustomButton>
         }
         <CustomButton
            isSmall
            isBlue
            onClick={() => setIsProductsPopupOpen(true)}
         >
            کالاها
         </CustomButton>
         {isProductsPopupOpen && (
            <Popup
               modal
               open={isProductsPopupOpen}
               nested
               onClose={() => setIsProductsPopupOpen(false)}
            >
               {
                  <VariantProducts
                     shopId={shopId}
                     variantId={variant.id}
                     productQuantities={variant.productQuantities}
                  />
               }
            </Popup>
         )}

         {isChangeVariantPopupOpen && (
            <AddChangeVariant
               variantToChange={variant}
               setIsPopUpOpen={() => setIsChangeVariantPopupOpen(false)}
            />
         )}
      </VariantContainer>
   );
};

const validateVariantInventorySpecs = (englishVariantSpecs) => {
   let isValid = true;
   let errors = [];

   // console.log(englishVariantSpecs);
   const numFromStrVariantInventorySpecs = Object.fromEntries(
      Object.entries(englishVariantSpecs).map(([k, v]) => [k, Number(v)])
   );
   const { mostPrice, leastPrice } = numFromStrVariantInventorySpecs;

   if (leastPrice > mostPrice) {
      isValid = false;
      errors.push('قیمت حداکثر نمی‌تواند بیشتر از قیمت حداقل باشد.');
   }
   for (let e of errors) {
      console.log(e);
      toastWarning(e);
   }

   return isValid;
};

export default VariantSummary;
