import theme from './theme.utils';

const selectCustomStyles = {
   option: (provided, state) => {
      return {
         ...provided,
         backgroundColor: state.isFocused
            ? theme.colors.secondary
            : state.isSelected
            ? theme.colors.dark
            : 'none',
         fontSize: theme.fontSizes.medium,
      };
   },
   control: (provided, state) => ({
      ...provided,
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.tertiary,
      width: state.selectProps.width,
      padding: 5,
   }),
   multiValueRemove: (provided, state) => {
      return { ...provided, borderRadius: '100%', padding: 6 };
   },
   menu: (provided, state) => {
      return { ...provided, backgroundColor: theme.colors.primary };
   },
   multiValue: (provided, state) => {
      return {
         ...provided,
         backgroundColor: theme.colors.tertiary,
         borderRadius: 0,
         padding: 3,
         margin: 2,
         fontSize: theme.fontSizes.medium,
      };
   },
   placeholder: (provided, state) => {
      return { ...provided, color: theme.colors.dark };
   },
};

export default selectCustomStyles;
