import styled from 'styled-components';

export const PhoneInputContainer = styled.div`
   min-width: 300px;
   display: flex;
   flex-direction: column;
   direction: rtl;
`;

export const PhoneInputTitle = styled.h2`
   margin: 10px 0;
   font-size: ${(props) => props.theme.fontSizes.small};
`;

export const ButtonsBarContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
`;
