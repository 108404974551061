import styled from 'styled-components';

export const ProductQuantityCompactContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   align-content: center;
   direction: rtl;
`;

export const ImageContainer = styled.div`
   width: 100px;
   height: 100px;
`;

export const Detail = styled.div``;

export const RemoveButtonContainer = styled.div`
   padding: 10px 20px;
   color: ${(props) => props.theme.colors.error};
   cursor: pointer;
`;
