import styled from 'styled-components';

export const AcknowledgeActionContainer = styled.div`
   direction: rtl;
   text-align: center;
   padding: 30px;

   button {
      margin: 20px;
   }
`;

export const CustomButtonContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
`;
