import styled from 'styled-components';

export const CalculatorContainer = styled.div`
   max-width: 100%;
   direction: rtl;
   padding: 30px;
   margin: auto;

   max-height: 90vh;
   overflow: auto;

   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-evenly;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      flex-direction: column;
   }
`;

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 30٪;
`;

export const FinalSellPrice = styled.div`
   width: 20%;
   display: flex;
   flex-direction: column;
   margin: 10px;
   font-weight: bold;
   font-size: ${(props) => props.theme.fontSizes.xxxLarge};
   color: ${(props) => props.theme.colors.secondary};

   text-align: center;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      width: 70%;
      margin-top: 50px;
   }
`;
