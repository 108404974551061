import React, { useEffect } from 'react';

//Redux Hooks
import { useDispatch } from 'react-redux';

//Components
import PhonePasswordInput from '../../components/phone-password-input/phone-password-input.component';

//Styles
import { SignInAndSignUpContainer } from './sign-in-and-sign-up.styles';

const SignInAndSignUpPage = () => {
   const dispatch = useDispatch();

   return (
      <SignInAndSignUpContainer>
         <PhonePasswordInput />
      </SignInAndSignUpContainer>
   );
};

export default SignInAndSignUpPage;
