import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.nav`
   width: 100%;
   display: flex;
   flex-direction: column;
   position: sticky;
   top: 0;
   z-index: 100;
   background-color: ${(props) => props.theme.colors.dark};

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
   }
`;

export const BaseNavbarContainer = styled.div`
   height: 90px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0 ${(props) => props.theme.spaces.extraLarge};

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      height: 70px;
      margin: 0 ${(props) => props.theme.spaces.small};
   }
`;

export const SearchAndMenuContainer = styled.div`
   height: 60px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0 ${(props) => props.theme.spaces.small};

   @media screen and (min-width: ${(props) => props.theme.breakPoints.md}) {
      display: none;
   }
`;

export const MenuContainer = styled.div`
   margin: 0 ${(props) => props.theme.spaces.small};
`;

export const LogoContainer = styled(Link)`
   width: 30%;
   height: 60%;
   display: flex;
   justify-content: flex-end;
   position: relative;

   img {
      /* position: absolute; */
      /* top: 0; */
      height: 100%;
      @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
         max-width: unset;
         height: 100%;
         margin-right: 10px;
      }
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      height: 65%;
   }
`;

export const BaseSearchContainer = styled.div`
   flex: 20%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-evenly;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      display: none;
   }
`;

export const AddedSearchContainer = styled.div`
   /* flex: 20%; */
   height: 100%;
   display: flex;
   margin-left: 10px;
   align-items: center;
   justify-content: flex-start;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
   }
`;

export const OptionsContainer = styled.div`
   /* flex: 80%; */
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   color: ${(props) => props.theme.colors.primary};
   font-size: ${(props) => props.theme.fontSizes.large};
   cursor: pointer;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      margin: auto;
   }
`;

export const ExtendedMenuContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   color: ${(props) => props.theme.colors.primary};
   font-size: ${(props) => props.theme.fontSizes.large};
   cursor: pointer;
`;

export const ExtendedMenuCategorysOption = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`;

export const ExtendedMenuOptionLink = styled(Link)`
   padding: ${(props) => props.theme.spaces.medium};
   color: ${(props) => props.theme.colors.primary};
`;

export const CategorysOption = styled.div`
   padding: ${(props) => props.theme.spaces.medium};

   @media screen and (max-width: ${(props) => props.theme.breakPoints.lg}) {
      font-size: ${(props) => props.theme.fontSizes.medium};
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      display: none;
   }
`;

export const OptionLink = styled(Link)`
   border-left: 1px solid ${(props) => props.theme.colors.primary};
   padding: ${(props) => props.theme.spaces.small};
   color: ${(props) => props.theme.colors.primary};
   text-align: center;
   background-color: ${(props) =>
      props.thisPage ? props.theme.colors.fortiary : 'none'};

   @media screen and (max-width: ${(props) => props.theme.breakPoints.lg}) {
      font-size: ${(props) => props.theme.fontSizes.medium};
   }

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      display: none;
   }
`;

export const LoginLink = styled.div`
   padding: ${(props) => props.theme.spaces.medium};
   @media screen and (max-width: ${(props) => props.theme.breakPoints.lg}) {
      font-size: ${(props) => props.theme.fontSizes.medium};
   }
`;

export const UserOptions = styled(LoginLink)`
   font-size: ${(props) => props.theme.fontSizes.small};

   .triangle {
      margin: 10px;
      font-size: ${(props) => props.theme.fontSizes.extraSmall};
   }

   :hover {
      background-color: ${(props) => props.theme.colors.darkGrey};
   }
`;
