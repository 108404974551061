export const replaceNewBrnadSeller = (sellers, brandSellerToBeReplaced) => {
   // console.log('sellers', sellers);
   // console.log('brandSellerToBeReplaced', brandSellerToBeReplaced);

   const existingSeller = sellers.find(
      (seller) => seller.id === brandSellerToBeReplaced.seller.id
   );

   // console.log('existingSeller', existingSeller);

   if (existingSeller) {
      const newExistingSeller = {
         ...existingSeller,
         brands: existingSeller.brands.map((brandSeller) =>
            brandSeller.id === brandSellerToBeReplaced.id
               ? { ...brandSellerToBeReplaced }
               : brandSeller
         ),
      };
      // console.log('newExistingSeller', newExistingSeller);
      return [
         ...sellers.filter((seller) => seller.id !== newExistingSeller.id),
         newExistingSeller,
      ];
   }
};
