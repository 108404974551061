import { createSelector } from 'reselect';

const selectUserSeller = (state) => state.userSeller;

export const selectUserSellers = createSelector(
   [selectUserSeller],
   (userSeller) => userSeller.sellers
);

export const selectIsUserSellersLoaded = createSelector(
   [selectUserSeller],
   (userSeller) => !!userSeller.sellers
);
