import styled from 'styled-components';
import CustomButton from '../../components/custom-button/custom-button.component';

export const InventoryPageContainer = styled.div`
   display: flex;
   justify-content: space-betewwn;
   margin: 0px auto;
   direction: rtl;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      flex-direction: column;
      margin: 10px;
   }
`;

export const InventorySideBar = styled.div`
   max-width: 30%;
   height: fit-content;
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-direction: column;
   gap: 5px;
   margin: 10px;
   direction: rtl;
   /* position: sticky;
   top: 100px; */

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      flex-direction: column;
      margin: 0px;
      max-width: unset;
      position: unset;
   }
`;

export const Filters = styled.div`
   display: flex;
   justify-content: flex-end;
   flex-direction: column;
   gap: 5px;
   margin: 10px;
   direction: rtl;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      flex-direction: column;
      margin: 10px;
   }
`;

export const ProductsList = styled.div`
   min-width: 70%;
   display: flex;
   flex-direction: column;
   gap: 5px;
   margin: 10px auto;
   direction: rtl;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
   }
`;

export const InventoryTitle = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: ${(props) => props.theme.fontSizes.xxxLarge};
   text-align: center;
   font-weight: bold;

   img {
      width: 80px;
      height: 80px;
      margin: 10px;
      @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
         margin: 0px;
      }
   }
`;

export const CartDropdownButton = styled(CustomButton)`
   margin: 20px 0px;
`;

export const AddChangeProductButton = styled(CustomButton)`
   margin: 20px auto;
`;

export const ExcelButton = styled(CustomButton)`
   margin: 20px auto;
`;

export const BrandsListButton = styled(CustomButton)`
   margin: 20px 0px;
`;

export const TransformFileContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: baseline;
   justify-content: center;
   text-align: center;
   width: 100%;
   border-top: 2px solid red;
   border-bottom: 2px solid red;
   padding: 15px;
   margin-bottom: 15px;
`;
