import React, { useState } from 'react';
import AcknowledgeAction from '../acknowledge-action/acknowledge-action.component';

//Styles
import {
   GroupContainer,
   RadioboxInputLabel,
   DeleteButton,
   Label,
   RadioboxContainer,
   HiddenRadiobox,
   SVGIcon,
} from './radiobox-input.styles';

const RadioboxInput = ({
   handleChange,
   label,
   deleteAction,
   width,
   ...props
}) => {
   const [isPopUpOpen, setIsPopUpOpen] = useState(false);
   return (
      <GroupContainer width={width}>
         <Label {...props}>
            <RadioboxContainer>
               <HiddenRadiobox onChange={handleChange} {...props} />
            </RadioboxContainer>
            {label ? <RadioboxInputLabel>{label}</RadioboxInputLabel> : null}
            {deleteAction ? (
               <DeleteButton onClick={() => setIsPopUpOpen(true)}>
                  <SVGIcon
                     height='14'
                     width='14'
                     viewBox='0 0 20 20'
                     ariaHidden='true'
                     focusable='false'
                     className='css-tj5bde-Svg'
                  >
                     <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
                  </SVGIcon>
               </DeleteButton>
            ) : null}
         </Label>

         <AcknowledgeAction
            isPopUpOpen={isPopUpOpen}
            setIsPopUpOpen={setIsPopUpOpen}
            yesFunction={() => deleteAction()}
         />
      </GroupContainer>
   );
};

export default RadioboxInput;
