const UserSellerActionTypes = {
   GET_USER_SELLERS_START: 'GET_USER_SELLERS_START',
   GET_USER_SELLERS_SUCCESS: 'GET_USER_SELLERS_SUCCESS',
   GET_USER_SELLERS_FAILURE: 'GET_USER_SELLERS_FAILURE',

   ADD_USER_SELLERS_START: 'ADD_USER_SELLERS_START',
   ADD_USER_SELLERS_SUCCESS: 'ADD_USER_SELLERS_SUCCESS',
   ADD_USER_SELLERS_FAILURE: 'ADD_USER_SELLERS_FAILURE',

   DEL_USER_SELLER: 'DEL_USER_SELLER',

   TOGGLE_SELLER_IS_KK_START: 'TOGGLE_SELLER_IS_KK_START',
   TOGGLE_SELLER_IS_KK_SUCCESS: 'TOGGLE_SELLER_IS_KK_SUCCESS',

   TOGGLE_BRAND_SELLER_IS_KK_START: 'TOGGLE_BRAND_SELLER_IS_KK_START',
   TOGGLE_BRAND_SELLER_IS_KK_SUCCESS: 'TOGGLE_BRAND_SELLER_IS_KK_SUCCESS',

   SET_BRAND_SELLER_OFFLIMIT_START: 'SET_BRAND_SELLER_OFFLIMIT_START',
   SET_BRAND_SELLER_OFFLIMIT_SUCCESS: 'SET_BRAND_SELLER_OFFLIMIT_SUCCESS',

   TOGGLE_SELLER_RANDOM_KK_START: 'TOGGLE_SELLER_RANDOM_KK_START',
   TOGGLE_SELLER_RANDOM_KK_SUCCESS: 'TOGGLE_SELLER_RANDOM_KK_SUCCESS',
};

export default UserSellerActionTypes;
