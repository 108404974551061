import React, { useEffect, useState } from 'react';

//Router Hooks
import {
   useParams,
   useNavigate,
   useLocation,
   useSearchParams,
} from 'react-router-dom';

//Redux Hooks
import { useDispatch, useSelector } from 'react-redux';

//Styles
import Spinner from '../../components/spinner/spinner.component';
import {
   selectShopVariants,
   selectIsShopVariantsLoaded,
   selectShopInfo,
} from '../../redux/shop/shop.selectors';
import { getShopStart } from '../../redux/shop/shop.actions';
import {
   ShopPageContainer,
   AddVariantButton,
   ShopSideBar,
   ShopTitle,
   Filters,
   ShopVariantList,
} from './shop.styles';
import Popup from 'reactjs-popup';
import SearchBar from '../../components/search-bar/search-bar.components';
import { persianNumberToEnglish } from '../../utils/font.utils';
import CheckboxInput from '../../components/checkbox-input/checkbox-input.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import BatchSortRadiobox from '../../components/batch-sort-radiobox/batch-sort-radiobox.component';
import { getProductsStart } from '../../redux/inventory/inventory.actions';
import AddChangeVariant from '../../components/add-change-variant/add-change-variant.component';
import VariantSummary from '../../components/variant-summary/variant-summary.compoonent';
const allPosibilities = [
   {
      sort_id: 0,
      name: 'جدیدترین',
      func: (v1, v2) => (v1, v2) => v2.id - v1.id,
   },
];

export const ShopPage = () => {
   console.log('ShopPage');
   const dispatch = useDispatch();
   const variants = useSelector(selectShopVariants);
   const isLoaded = useSelector(selectIsShopVariantsLoaded);
   const info = useSelector(selectShopInfo);
   const params = useParams();
   const currentUser = useSelector(selectCurrentUser);
   const navigate = useNavigate();
   const location = useLocation();
   let [searchParams, setSearchParams] = useSearchParams();

   const [noProducts, setNoProducts] = useState(false);

   useEffect(() => {
      dispatch(getProductsStart());
   }, []);

   const [query, setQuery] = useState('');
   useEffect(() => {
      dispatch(getShopStart(params.shopId));
   }, [dispatch, params.shopId]);

   const handleSearchChange = (event) => {
      const { value } = event.target;

      setQuery(persianNumberToEnglish(value));
   };

   const [sortFunction, setSortFunction] = useState(
      (obj1, obj2) => (obj1, obj2) => obj1.price - obj2.price
   );

   const handleSort = (sortfunc) => {
      setSortFunction(sortfunc);
   };

   console.log(query);
   const getFilteredvs = () => {
      const filtered = variants
         .sort((v1, v2) => sortFunction(v1, v2))
         .filter((v) =>
            persianNumberToEnglish(query.toLowerCase())
               .split(' ')
               .some((s) => v.name.toLowerCase().includes(s))
         )
         .filter((v) =>
            noProducts
               ? !(v.productQuantities && v.productQuantities.length > 0)
               : true
         );

      const page = searchParams.get('page') ? searchParams.get('page') : 1;
      return !!query && query.length > 1
         ? filtered
         : filtered.slice((page - 1) * 150, 150 + (page - 1) * 150);
   };

   return currentUser && isLoaded ? (
      <div>
         {variants.length > 0 ? (
            <ShopPageContainer>
               <ShopSideBar>
                  <ShopTitle>{info.name}</ShopTitle>
                  <SearchBar
                     placeHolder='جستجو'
                     handleChange={handleSearchChange}
                  ></SearchBar>
                  <Filters>
                     <CheckboxInput
                        label={'بدون کالا'}
                        checked={noProducts}
                        handleChange={() => setNoProducts(!noProducts)}
                     />
                  </Filters>
                  {
                     // <ExcelButton
                     //    isGreen
                     //    onClick={() => dispatch(getShopExcelStart(params.shopId))}
                     // >
                     //    دریافت اکسل
                     // </ExcelButton>
                  }
                  <div>تعداد: {getFilteredvs().length}</div>
               </ShopSideBar>
               <ShopVariantList>
                  <BatchSortRadiobox
                     allPosibilities={allPosibilities}
                     handleSort={(sortFunction) => handleSort(sortFunction)}
                  />
                  {getFilteredvs().map((v) => (
                     <VariantSummary
                        variant={v}
                        key={v.id}
                        shopId={params.shopId}
                     />
                  ))}
               </ShopVariantList>
            </ShopPageContainer>
         ) : (
            <div>
               <ShopTitle>{info.name}</ShopTitle>
               <Spinner />
            </div>
         )}
      </div>
   ) : (
      <Spinner />
   );
};

export default ShopPage;
