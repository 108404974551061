//Libraries
import { toast } from 'react-toastify';

//Utils
import { englishNumberToPersian } from './font.utils';

//Configs
const toastConfig = {
   position: 'top-right',
   pauseOnHover: true,
   progress: undefined,
};

export const toastSuccess = function (message) {
   toast.success(englishNumberToPersian(message), toastConfig);
};

export const toastError = function (message) {
   toast.error(englishNumberToPersian(message), toastConfig);
};

export const toastMessage = function (message) {
   toast(englishNumberToPersian(message), toastConfig);
};

export const toastWarning = function (message) {
   toast.warning(englishNumberToPersian(message), toastConfig);
};
