import styled from 'styled-components';

export const DKPCCompactContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   direction: rtl;
`;

export const ImageContainer = styled.a`
   width: 100px;
   height: 100px;
`;

export const Detail = styled.div`
   width: 100px;
`;

export const StockContainer = styled.span`
   margin: 0px;
   min-width: 50px;
`;

export const NameContainer = styled.a`
   width: 200px;
`;
