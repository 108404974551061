import React, { Suspense, lazy } from 'react';

import './App.css';

import { ThemeProvider } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import { Routes, Route } from 'react-router-dom';

import theme from './utils/theme.utils';

import ErrorBoundary from './components/error-boundary/error-boundary.component';
import Spinner from './components/spinner/spinner.component';
import Header from './components/header/header.component';
import { GlobalStyle } from './global.styles';
import StyledToastContainer from './styled-toast-container.styles';
import { BodyContainer } from './App.styles';
import SellerOrdersPage from './pages/seller-orders/seller-orders.component';
import InventoryPage from './pages/inventory/inventory.component';
import ShopPage from './pages/shop/shop.component';

const HomePage = lazy(() => import('./pages/homepage/homepage.component'));
const SellersList = lazy(() =>
   import('./pages/sellers-list/sellers-list.component')
);
const SellerPage = lazy(() => import('./pages/seller/seller.component'));
const BrandSellerList = lazy(() =>
   import('./pages/brand-seller-list/brand-seller-list.component')
);

function App() {
   return (
      <div>
         <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Header />
            <BodyContainer>
               <ErrorBoundary>
                  <Suspense fallback={<Spinner />}>
                     <Routes>
                        <Route exact path='/' element={<HomePage />} />
                        <Route
                           exact
                           path='/anbar'
                           element={<InventoryPage />}
                        />
                        <Route path='/site/:shopId' element={<ShopPage />} />
                        <Route
                           exact
                           path='/all-sellers'
                           element={<SellersList />}
                        />
                        <Route
                           path='/all-sellers/:sellerId'
                           element={<SellerPage />}
                        />
                        <Route
                           path='/all-sellers/:sellerId/orders'
                           element={<SellerOrdersPage />}
                        />
                        <Route
                           path='/all-sellers/:sellerId/brands'
                           element={<BrandSellerList />}
                        />
                     </Routes>
                  </Suspense>
               </ErrorBoundary>
            </BodyContainer>
            <StyledToastContainer
               position='top-right'
               autoClose={2000}
               hideProgressBar={false}
               newestOnTop={false}
               closeOnClick
               rtl={true}
               pauseOnFocusLoss
               draggable={false}
               pauseOnHover
            />
         </ThemeProvider>
      </div>
   );
}

export default App;
