import styled from 'styled-components';

export const SearchContainer = styled.div`
   direction: rtl;
`;

export const InputContainer = styled.input.attrs({ type: 'search' })`
   min-width: 250px;
   height: 40px;
   padding: 6px;
   border: solid 1px ${(props) => props.theme.colors.fortiary};
   background-color: ${(props) => props.theme.colors.primary};
   font-size: ${(props) => props.theme.fontSizes.medium};
   font-family: 'IRANSans';

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      min-width: 180px;
   }
`;
