import { takeLatest, put, all, call, select } from 'redux-saga/effects';

//Libraries
import axios from 'axios';

import UserSellerActionTypes from './user-seller.types';

//Redux Actions
import {
   getUserSellersSuccess,
   getUserSellersFailure,
   addUserSellersSuccess,
   addUserSellersFailure,
   toggleSellerIsKKSuccess,
   toggleBrandSellerIsKKSuccess,
   setBrandSellerOffLimitSuccess,
   toggleSellerRandomKKSuccess,
} from './user-seller.actions';

//Utils
import { toastError, toastSuccess } from '../../utils/toast-redux.utils';
import { selectUserToken } from '../user/user.selectors';
import { toast } from 'react-toastify';
import { getSellerDKPCsStart } from '../seller/seller.actions';

export function* getUserSellersSaga() {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.get('/sellers/', config);

      yield put(getUserSellersSuccess(response.data.data));
   } catch (error) {
      yield put(getUserSellersFailure(error.response.data.message));
   }
}

export function* addUserSellersSaga({ payload }) {
   //
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post('/sellers/', payload, config);

      yield put(addUserSellersSuccess(response.data.data));
   } catch (error) {
      toast.error(error.response.data.message);
      yield put(addUserSellersFailure(error.response.data.message));
   }
}

export function* delUserSellerSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.delete('/sellers/', {
         data: { sellerId: payload },
         ...config,
      });

      toast.success('کالا با موفقیت حذف شد.');
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* toggleSellerIsKKSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.put(
         '/sellers/',
         { sellerId: payload },
         config
      );

      toast.success('کک با موفقیت تغییر کرد.');
      yield put(toggleSellerIsKKSuccess(response.data.data));
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* toggleBrandSellerIsKKSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.put(
         '/sellers/brand/',
         { brandSellerId: payload },
         config
      );

      toast.success('کک برند با موفقیت تغییر کرد.');
      yield put(toggleBrandSellerIsKKSuccess(response.data.data));
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* setBrandSellerOffLimitSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post(
         '/sellers/brand/',
         {
            brandOffLimit: payload.offLimit,
            brandSellerId: payload.brandSellerId,
         },
         config
      );

      toast.success('محدودیت تخفیف برند با موفقیت تغییر کرد.');
      yield put(setBrandSellerOffLimitSuccess(response.data.data));
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* toggleSellerRandomKKSaga({ payload }) {
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.put(
         '/sellers/' + payload + '/randomkk/',
         {},
         config
      );

      toast.success('کک رندوم با موفقیت تغییر کرد.');
      yield put(toggleSellerRandomKKSuccess(response.data.data));
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* toggleSellerRandomKKSuccessSaga({ payload }) {
   yield put(getSellerDKPCsStart(payload.id));
}

export function* toastFailure({ payload: message }) {
   yield toastError(message);
}

export function* toastSuccessAndGoBack({
   payload: {
      data: { message },
   },
}) {
   yield toastSuccess(message);
}

export function* onGetUserSellersStart() {
   yield takeLatest(
      UserSellerActionTypes.GET_USER_SELLERS_START,
      getUserSellersSaga
   );
}

export function* onAddUserSellersStart() {
   yield takeLatest(
      UserSellerActionTypes.ADD_USER_SELLERS_START,
      addUserSellersSaga
   );
}

export function* onDelUserSeller() {
   yield takeLatest(UserSellerActionTypes.DEL_USER_SELLER, delUserSellerSaga);
}

export function* onToggleSellerIsKKStart() {
   yield takeLatest(
      UserSellerActionTypes.TOGGLE_SELLER_IS_KK_START,
      toggleSellerIsKKSaga
   );
}

export function* onToggleBrandSellerIsKKStart() {
   yield takeLatest(
      UserSellerActionTypes.TOGGLE_BRAND_SELLER_IS_KK_START,
      toggleBrandSellerIsKKSaga
   );
}

export function* onSetBrandSellerOffLimitStart() {
   yield takeLatest(
      UserSellerActionTypes.SET_BRAND_SELLER_OFFLIMIT_START,
      setBrandSellerOffLimitSaga
   );
}

export function* onToggleSellerRandomKKStart() {
   yield takeLatest(
      UserSellerActionTypes.TOGGLE_SELLER_RANDOM_KK_START,
      toggleSellerRandomKKSaga
   );
}

export function* onToggleSellerRandomKKSuccess() {
   yield takeLatest(
      UserSellerActionTypes.TOGGLE_SELLER_RANDOM_KK_SUCCESS,
      toggleSellerRandomKKSuccessSaga
   );
}
export function* userSellerSagas() {
   yield all([
      call(onGetUserSellersStart),
      call(onAddUserSellersStart),
      call(onDelUserSeller),
      call(onToggleSellerIsKKStart),
      call(onToggleBrandSellerIsKKStart),
      call(onSetBrandSellerOffLimitStart),
      call(onToggleSellerRandomKKStart),
      call(onToggleSellerRandomKKSuccess),
   ]);
}
