const UserActionTypes = {
   SET_CURRENT_USER: 'SET_CURRENT_USER',

   PHONE_SEND_OTP_START: 'PHONE_SEND_OTP_START',
   PHONE_REGISTER_OR_LOGIN_START: 'PHONE_REGISTER_OR_LOGIN_START',
   PHONE_REGISTER_OR_LOGIN_BACKEND: 'PHONE_REGISTER_OR_LOGIN_BACKEND',
   PHONE_LOGIN_START: 'PHONE_LOGIN_START',
   PHONE_LOGIN_BACKEND: 'PHONE_LOGIN_BACKEND',
   PHONE_REGISTER_START: 'PHONE_REGISTER_START',
   PHONE_REGISTER_BACKEND: 'PHONE_REGISTER_BACKEND',
   PHONE_FORGET_START: 'PHONE_FORGET_START',
   PHONE_FORGET_BACKEND: 'PHONE_FORGET_BACKEND',

   SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
   SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

   CHECK_USER_SESSION: 'CHECK_USER_SESSION',

   SIGN_OUT: 'SIGN_OUT',
};

export default UserActionTypes;
