import styled from 'styled-components';

export const BodyContainer = styled.div`
   padding: ${(props) => props.theme.spaces.small}
      ${(props) => props.theme.spaces.medium};

   min-height: 81.5vh;

   @media screen and (max-width: ${(props) => props.theme.breakPoints.md}) {
      padding: ${(props) => props.theme.spaces.medium} 0;
      min-height: 72.5vh;
   }
`;
