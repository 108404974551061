import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
   AddChangeProductButton,
   ButtonsBarContainer,
   FormContainer,
   ProductInputContainer,
   RtLContainer,
   SectionTitle,
} from './add-change-product.styles';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import { getPersianCurrency } from '../../utils/font.utils';
import { addChangeProductStart } from '../../redux/inventory/inventory.actions';
import Popup from 'reactjs-popup';

const AddChangeProduct = ({ productToChange, setIsPopUpOpen }) => {
   const dispatch = useDispatch();
   const [isAddChangeProductPopUpOpen, setIsAddChangeProductPopUpOpen] =
      useState(!!productToChange);
   const [product, setProduct] = useState({
      name: productToChange ? productToChange.name : '',
      imageLink: productToChange ? productToChange.imageLink : '',
      productId: productToChange ? productToChange.id : '',
      sstid: productToChange ? productToChange.sstid : '۰',
   });

   const { name, imageLink, sstid } = product;

   const handleSubmit = async (event) => {
      event.preventDefault();
      dispatch(
         addChangeProductStart(product, () => {
            setIsAddChangeProductPopUpOpen(false);
            if (setIsPopUpOpen) setIsPopUpOpen();
         })
      );
   };

   const handleChange = (event) => {
      const { value, name } = event.target;
      setProduct({
         ...product,
         [name]: name.toLowerCase().includes('price')
            ? getPersianCurrency(value)
            : value,
      });
   };

   return (
      <ProductInputContainer>
         {!isAddChangeProductPopUpOpen && (
            <AddChangeProductButton
               inverted
               onClick={() => setIsAddChangeProductPopUpOpen(true)}
            >
               اضافه کردن کالا
            </AddChangeProductButton>
         )}
         {isAddChangeProductPopUpOpen && (
            <Popup
               modal
               open={isAddChangeProductPopUpOpen}
               onClose={() => {
                  setIsAddChangeProductPopUpOpen(false);
                  if (setIsPopUpOpen) setIsPopUpOpen();
               }}
            >
               <RtLContainer>
                  <SectionTitle>مشخصات کالا را وارد کنید</SectionTitle>
                  <form onSubmit={handleSubmit}>
                     <FormContainer>
                        <FormInput
                           name='name'
                           type='text'
                           handleChange={handleChange}
                           value={name}
                           label='نام کالا'
                           required
                        />
                        <FormInput
                           name='imageLink'
                           type='text'
                           handleChange={handleChange}
                           value={imageLink}
                           label='لینک تصویر'
                        />
                        <FormInput
                           name='sstid'
                           type='text'
                           handleChange={handleChange}
                           value={sstid}
                           label='شناسه اختصاصی کالا'
                        />
                     </FormContainer>
                     <ButtonsBarContainer>
                        <CustomButton
                           type='submit'
                           disabled={Object.keys(product)
                              .filter((k) => k !== 'productId')
                              .map((k) => product[k])
                              .some((v) => v === '')}
                        >
                           ثبت کالا
                        </CustomButton>
                     </ButtonsBarContainer>
                  </form>
               </RtLContainer>
            </Popup>
         )}
      </ProductInputContainer>
   );
};

export default AddChangeProduct;
