import React, { useState } from 'react';
import { useEffect } from 'react';

//Components
import RadioboxInput from '../radiobox-input/radiobox-input.component';

//Styles
import { SortContainer } from './batch-sort-radiobox.styles';

const BatchSortRadiobox = ({ handleSort, isForSearch, allPosibilities }) => {
   const [checked, setChecked] = useState(0);
   const handleToggle = (value) => {
      setChecked(value);
      handleSort(allPosibilities[value].func);
   };

   useEffect(() => {
      handleSort(allPosibilities[checked].func);
   }, []);

   // console.log('isForSearch', isForSearch, allPosibilities);

   const renderSortsList = () =>
      allPosibilities.map((item, index) => (
         <RadioboxInput
            key={index}
            label={item.name}
            checked={checked === item.sort_id}
            onChange={() => handleToggle(item.sort_id)}
         />
      ));

   return <SortContainer>{renderSortsList()}</SortContainer>;
};

export default BatchSortRadiobox;
