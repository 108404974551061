export const replceNewDKPC = (dkpcs, dkpcToBeReplaced) => {
   console.log('dkpcs', dkpcs);
   console.log('dkpcToBeReplaced', dkpcToBeReplaced);

   const existingDKPC = dkpcs.find((dkpc) => dkpc.id === dkpcToBeReplaced.id);

   console.log('existingDKPC', existingDKPC);

   if (existingDKPC) {
      return dkpcs.map((dkpc) =>
         dkpc.id === dkpcToBeReplaced.id ? { ...dkpcToBeReplaced } : dkpc
      );
   }
};
