const SellerActionTypes = {
   GET_SELLER_DKPCS_START: 'GET_SELLER_DKPCS_START',
   GET_SELLER_DKPCS_SUCCESS: 'GET_SELLER_DKPCS_SUCCESS',
   GET_SELLER_DKPCS_FAILURE: 'GET_SELLER_DKPCS_FAILURE',

   ADD_SELLER_DKPC_START: 'ADD_SELLER_DKPC_START',
   ADD_SELLER_DKPC_SUCCESS: 'ADD_SELLER_DKPC_SUCCESS',
   ADD_SELLER_DKPC_FAILURE: 'ADD_SELLER_DKPC_FAILURE',

   SET_SELLER_DKPC_START: 'SET_SELLER_DKPC_START',
   SET_SELLER_DKPC_SUCCESS: 'SET_SELLER_DKPC_SUCCESS',
   SET_SELLER_DKPC_FAILURE: 'SET_SELLER_DKPC_FAILURE',

   DEL_SELLER_DKPC: 'DEL_SELLER_DKPC',

   CHECK_SELLER_DKPC: 'CHECK_SELLER_DKPC',

   TOGGLE_SELLER_DKPC_ISKKON_START: 'TOGGLE_SELLER_DKPC_ISKKON_START',
   TOGGLE_SELLER_DKPC_ISKKON_SUCCESS: 'TOGGLE_SELLER_DKPC_ISKKON_SUCCESS',

   TOGGLE_SELLER_DKPC_ISACTIVEBOT_START: 'TOGGLE_SELLER_DKPC_ISACTIVEBOT_START',
   TOGGLE_SELLER_DKPC_ISACTIVEBOT_SUCCESS:
      'TOGGLE_SELLER_DKPC_ISACTIVEBOT_SUCCESS',

   GET_SELLER_EXCEL_START: 'GET_SELLER_EXCEL_START',

   GET_SELLER_ORDERS_START: 'GET_SELLER_ORDERS_START',
   GET_SELLER_ORDERS_SUCCESS: 'GET_SELLER_ORDERS_SUCCESS',
   GET_SELLER_ORDERS_FAILURE: 'GET_SELLER_ORDERS_FAILURE',

   CHECK_SELLER_DKPC_ORDER: 'CHECK_SELLER_DKPC_ORDER',
   REMOVE_SELLER_DKPC_ORDER: 'REMOVE_SELLER_DKPC_ORDER',
};

export default SellerActionTypes;
