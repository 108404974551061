import styled from 'styled-components';

export const ErrorImageOverlay = styled.div`
   height: 60vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`;

export const ErrorImageText = styled.h2`
   font-weight: bold;
   font-size: ${(props) => props.theme.fontSizes.large};
   color: ${(props) => props.theme.colors.dark};
   direction: rtl;
`;
