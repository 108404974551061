import ShopActionTypes from './shop.types';

export const getShopStart = (shopIId) => ({
   type: ShopActionTypes.GET_SHOP_START,
   payload: shopIId,
});

export const getShopSuccess = (products) => ({
   type: ShopActionTypes.GET_SHOP_SUCCESS,
   payload: products,
});

export const getShopFailure = (errorMessage) => ({
   type: ShopActionTypes.GET_SHOP_FAILURE,
   payload: errorMessage,
});

export const addChangeShopVariantStart = (shopId, v, closePopup) => ({
   type: ShopActionTypes.ADD_CHANGE_SHOP_VARIANT_START,
   payload: { shopId, v, closePopup },
});

export const addChangeShopVariantSuccess = (v) => ({
   type: ShopActionTypes.ADD_CHANGE_SHOP_VARIANT_SUCCESS,
   payload: v,
});

export const addChangeShopVariantFailure = (errorMessage) => ({
   type: ShopActionTypes.ADD_CHANGE_SHOP_VARIANT_FAILURE,
   payload: errorMessage,
});

export const delShopVariant = (vId, shopId) => ({
   type: ShopActionTypes.DEL_SHOP_VARIANT,
   payload: { shopId, vId },
});
