import InventoryActionTypes from './inventory.types';

const INITIAL_STATE = {
   products: null,
   error: null,
};

const inventoryReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case InventoryActionTypes.GET_PRODUCTS_SUCCESS:
         return {
            ...state,
            products: action.payload,
            error: null,
         };
      case InventoryActionTypes.ADD_CHANGE_PRODUCT_SUCCESS:
         return {
            ...state,
            products: [
               ...state.products.filter((p) => p.id !== action.payload.id),
               action.payload,
            ],
            error: null,
         };
      case InventoryActionTypes.GET_PRODUCTS_FAILURE:
         return {
            ...state,
            products: action.payload,
         };

      default:
         return state;
   }
};

export default inventoryReducer;
