import { takeLatest, put, all, call, select } from 'redux-saga/effects';

//Libraries
import axios from 'axios';
import download from 'downloadjs';

import SellerActionTypes from './seller.types';

//Redux Actions
import {
   getSellerDKPCsSuccess,
   getSellerDKPCsFailure,
   addSellerDKPCSuccess,
   addSellerDKPCFailure,
   setSellerDKPCSuccess,
   setSellerDKPCFailure,
   toggleSellerDKPCIsKKOnSuccess,
   toggleSellerDKPCIsActiveBotSuccess,
   getSellerOrdersSuccess,
   getSellerOrdersFailure,
   removeSellerDKPCOrder,
} from './seller.actions';

//Utils
import { toastError, toastSuccess } from '../../utils/toast-redux.utils';
import { selectUserToken } from '../user/user.selectors';
import { toast } from 'react-toastify';

export function* getSellerDKPCsSaga({ payload }) {
   //
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.get('/sellers/' + payload + '/', config);
      //
      yield put(getSellerDKPCsSuccess(response.data.data.dkpcs));
   } catch (error) {
      if (error.response)
         yield put(getSellerDKPCsFailure(error.response.data.message));
   }
}

export function* getSellerOrdersSaga({ payload }) {
   //
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.get(
         '/sellers/' + payload + '/orders/',
         config
      );

      // console.log('response.data', response.data.dkpcs);
      yield put(getSellerOrdersSuccess(response.data.dkpcs));
   } catch (error) {
      if (error.response)
         yield put(getSellerOrdersFailure(error.response.data.message));
   }
}

export function* addSellerDKPCSaga({
   payload: { sellerId, dkpc, closePopup },
}) {
   try {
      const jwt_token = yield select(selectUserToken);
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post(
         '/sellers/' + sellerId + '/',
         dkpc,
         config
      );
      //

      yield put(addSellerDKPCSuccess(response.data.data));
      toast.success('کالا با موفقیت اضافه شد.');
      closePopup();
   } catch (error) {
      if (error.response) {
         // console.log(error.response);
         yield put(addSellerDKPCFailure(error.response.data.message));
         toast.error(error.response.data.message);
      }
   }
}

export function* setSellerDKPCSaga({ payload: { sellerId, dkpc }, payload }) {
   // console.log(sellerId, dkpc, payload);
   try {
      const jwt_token = yield select(selectUserToken);
      //
      //

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.put(
         '/sellers/' + sellerId + '/',
         dkpc,
         config
      );

      yield put(setSellerDKPCSuccess(response.data.data));

      toast.success(response.data.message);
   } catch (error) {
      yield put(setSellerDKPCFailure(error));
   }
}

export function* delSellerDKPCSaga({ payload: { sellerId, dkpcId }, payload }) {
   console.log(sellerId, dkpcId, payload);
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.delete('/sellers/' + sellerId + '/', {
         data: { dkpcId: dkpcId },
         ...config,
      });

      toast.success('کالا با موفقیت حذف شد.');
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* checkSellerDKPCSaga({
   payload: { sellerId, dkpcId },
   payload,
}) {
   console.log(sellerId, dkpcId, payload);
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post(
         '/sellers/' + sellerId + /check/,
         { dkpcId: dkpcId },
         config
      );

      toast.success('کالا با موفقیت چک شد.');
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* checkSellerDKPCOrderSaga({
   payload: { sellerId, dkpcId },
   payload,
}) {
   console.log(sellerId, dkpcId, payload);
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post(
         '/sellers/' + sellerId + '/orders/check/',
         { dkpcId: dkpcId },
         config
      );

      if (response.data.message === 'ok') {
         toast.success('!کالا بررسی شد.');
         yield put(removeSellerDKPCOrder(dkpcId));
      }
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* toggleSellerDKPCIsKKOnSaga({ payload: { sellerId, dkpcId } }) {
   console.log(sellerId, dkpcId);
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.put(
         '/sellers/' + sellerId + /toggle/,
         { dkpcId: dkpcId },
         config
      );

      yield put(toggleSellerDKPCIsKKOnSuccess(response.data.data));
      toast.success('کک کالا با موفقیت تغییر کرد.');
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* toggleSellerDKPCIsActiveBotSaga({
   payload: { sellerId, dkpcId },
}) {
   console.log(sellerId, dkpcId);
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
         },
      };
      const response = yield axios.post(
         '/sellers/' + sellerId + /toggle/,
         { dkpcId: dkpcId },
         config
      );

      yield put(toggleSellerDKPCIsActiveBotSuccess(response.data.data));
      toast.success('وضعیت فعالیت‌ کالا با موفقیت تغییر کرد.');
   } catch (error) {
      toast.error('با مشکل مواجه شدیم.');
   }
}

export function* getExcelSaga({ payload }) {
   const sellerId = payload;

   console.log('getExcelSaga');
   try {
      const jwt_token = yield select(selectUserToken);

      const config = {
         headers: {
            Content_type: 'application/json',
            Authorization: 'Bearer ' + jwt_token,
            response_type: 'blob',
         },
      };
      const response = yield axios.get(
         '/sellers/' + sellerId + /excel/,
         config
      );

      console.log('response', response);

      const content = response.headers['content-type'];
      yield download(
         new Blob(['\ufeff', response.data]),
         `dkpc-report-${sellerId}.csv`,
         content
      );
   } catch (error) {
      yield console.log(error.message);
   }
}

export function* toastFailure({ payload: message }) {
   yield toastError(message);
}

export function* toastSuccessAndGoBack({
   payload: {
      data: { message },
   },
}) {
   yield toastSuccess(message);
}

export function* onGetSellerDKPCsStart() {
   yield takeLatest(
      SellerActionTypes.GET_SELLER_DKPCS_START,
      getSellerDKPCsSaga
   );
}

export function* onAddSellerDKPCStart() {
   yield takeLatest(SellerActionTypes.ADD_SELLER_DKPC_START, addSellerDKPCSaga);
}

export function* onSetSellerDKPCStart() {
   yield takeLatest(SellerActionTypes.SET_SELLER_DKPC_START, setSellerDKPCSaga);
}

export function* onDelSellerDKPC() {
   yield takeLatest(SellerActionTypes.DEL_SELLER_DKPC, delSellerDKPCSaga);
}

export function* onCheckSellerDKPC() {
   yield takeLatest(SellerActionTypes.CHECK_SELLER_DKPC, checkSellerDKPCSaga);
}

export function* onCheckSellerDKPCOrder() {
   yield takeLatest(
      SellerActionTypes.CHECK_SELLER_DKPC_ORDER,
      checkSellerDKPCOrderSaga
   );
}

export function* onToggleSellerDKPCIsKKOnStart() {
   yield takeLatest(
      SellerActionTypes.TOGGLE_SELLER_DKPC_ISKKON_START,
      toggleSellerDKPCIsKKOnSaga
   );
}

export function* onToggleSellerDKPCIsActiveBotStart() {
   yield takeLatest(
      SellerActionTypes.TOGGLE_SELLER_DKPC_ISACTIVEBOT_START,
      toggleSellerDKPCIsActiveBotSaga
   );
}

export function* onGetExcelStart() {
   yield takeLatest(SellerActionTypes.GET_SELLER_EXCEL_START, getExcelSaga);
}

export function* onGetSellerOrdersStart() {
   yield takeLatest(
      SellerActionTypes.GET_SELLER_ORDERS_START,
      getSellerOrdersSaga
   );
}
export function* sellerSagas() {
   yield all([
      call(onGetSellerDKPCsStart),
      call(onAddSellerDKPCStart),
      call(onSetSellerDKPCStart),
      call(onDelSellerDKPC),
      call(onCheckSellerDKPC),
      call(onCheckSellerDKPCOrder),
      call(onToggleSellerDKPCIsKKOnStart),
      call(onToggleSellerDKPCIsActiveBotStart),
      call(onGetExcelStart),
      call(onGetSellerOrdersStart),
   ]);
}
