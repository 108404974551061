import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
   AddChangeVariantButton,
   ButtonsBarContainer,
   FormContainer,
   VariantInputContainer,
   RtLContainer,
   SectionTitle,
} from './add-change-variant.styles';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import { getPersianCurrency } from '../../utils/font.utils';
import Popup from 'reactjs-popup';
import { addChangeShopVariantStart } from '../../redux/shop/shop.actions';

const AddChangeVariant = ({ variantToChange, shopId, setIsPopUpOpen }) => {
   const dispatch = useDispatch();
   const [isAddChangeVariantPopUpOpen, setIsAddChangeVariantPopUpOpen] =
      useState(!!variantToChange);
   const [variant, setVariant] = useState({
      name: variantToChange ? variantToChange.name : '',
      imageLink: variantToChange ? variantToChange.imageLink : '',
      variantId: variantToChange ? variantToChange.id : '',
   });

   const { name, imageLink } = variant;

   const handleSubmit = async (event) => {
      event.preventDefault();
      dispatch(
         addChangeShopVariantStart(shopId, variant, () => {
            setIsAddChangeVariantPopUpOpen(false);
            if (setIsPopUpOpen) setIsPopUpOpen();
         })
      );
   };

   const handleChange = (event) => {
      const { value, name } = event.target;
      setVariant({
         ...variant,
         [name]: name.toLowerCase().includes('price')
            ? getPersianCurrency(value)
            : value,
      });
   };

   return (
      <VariantInputContainer>
         {!isAddChangeVariantPopUpOpen && (
            <AddChangeVariantButton
               inverted
               onClick={() => setIsAddChangeVariantPopUpOpen(true)}
            >
               سینک با فروشگاه
            </AddChangeVariantButton>
         )}
         {isAddChangeVariantPopUpOpen && (
            <Popup
               modal
               open={isAddChangeVariantPopUpOpen}
               onClose={() => {
                  setIsAddChangeVariantPopUpOpen(false);
                  if (setIsPopUpOpen) setIsPopUpOpen();
               }}
            >
               <RtLContainer>
                  <SectionTitle>مشخصات کالا را وارد کنید</SectionTitle>
                  <form onSubmit={handleSubmit}>
                     <FormContainer>
                        <FormInput
                           name='name'
                           type='text'
                           handleChange={handleChange}
                           value={name}
                           label='نام کالا'
                           required
                        />
                        <FormInput
                           name='imageLink'
                           type='text'
                           handleChange={handleChange}
                           value={imageLink}
                           label='لینک تصویر'
                        />
                     </FormContainer>
                     <ButtonsBarContainer>
                        <CustomButton
                           type='submit'
                           disabled={Object.keys(variant)
                              .filter((k) => k !== 'variantId')
                              .map((k) => variant[k])
                              .some((v) => v === '')}
                        >
                           ثبت کالا
                        </CustomButton>
                     </ButtonsBarContainer>
                  </form>
               </RtLContainer>
            </Popup>
         )}
      </VariantInputContainer>
   );
};

export default AddChangeVariant;
