import React from 'react';
import { useSelector } from 'react-redux';
// import { selectSearchQuery } from '../../redux/shop/shop.selectors';
import { SearchContainer, InputContainer } from './search-bar.styles';

const SearchBar = ({ handleChange, placeHolder, ...props }) => {
   // const searchQuery = useSelector(selectSearchQuery);
   return (
      <SearchContainer>
         <InputContainer
            // value={searchQuery}
            onChange={handleChange}
            placeholder={placeHolder}
            {...props}
         ></InputContainer>
      </SearchContainer>
   );
};

export default SearchBar;
