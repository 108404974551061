import React from 'react';

//Styles
import {
   GroupContainer,
   CheckboxInputLabel,
   CheckboxContainer,
   HiddenCheckbox,
   StyledCheckbox,
   Label,
   Icon,
} from './checkbox-input.styles';

const CheckboxInput = ({ handleChange, label, ...props }) => {
   // console.log({ ...props });
   return (
      <GroupContainer>
         <Label>
            {
               <CheckboxContainer>
                  <HiddenCheckbox onChange={handleChange} {...props} />
                  <StyledCheckbox {...props}>
                     <Icon viewBox='0 0 24 24'>
                        <polyline points='20 6 9 17 4 12' />
                     </Icon>
                  </StyledCheckbox>
               </CheckboxContainer>
            }
            {label ? <CheckboxInputLabel>{label}</CheckboxInputLabel> : null}
         </Label>
      </GroupContainer>
   );
};

export default CheckboxInput;
